import { SupportedChainId } from '../constants/chains'

const ETHERSCAN_PREFIXES: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: 'https://etherscan.io',
  [SupportedChainId.ROPSTEN]: 'https://ropsten.etherscan.io',
  [SupportedChainId.RINKEBY]: 'https://rinkeby.etherscan.io',
  [SupportedChainId.GOERLI]: 'https://goerli.etherscan.io',
  [SupportedChainId.KOVAN]: 'https://kovan.etherscan.io',
  [SupportedChainId.POLYGON_MUMBAI]: 'https://mumbai.polygonscan.com',
  [SupportedChainId.POLYGON]: 'https://polygonscan.com',
  [SupportedChainId.BSC_TESTNET]: 'https://testnet.bscscan.com',
  [SupportedChainId.BSC_MAINNET]: 'https://www.bscscan.com',
  [SupportedChainId.AVALANCHE_MAINNET]: 'https://avascan.info/blockchain/c',
  [SupportedChainId.AVALANCHE_FUJI_TESTNET]: 'https://testnet.avascan.info/blockchain/c',
  [SupportedChainId.FANTOM_OPERA_MAINNET]: 'https://ftmscan.com',
  [SupportedChainId.FANTOM_OPERA_TESTNET]: 'https://testnet.ftmscan.com',
  [SupportedChainId.GNOSIS]: 'https://blockscout.com/',
  [SupportedChainId.OPTIMISTIC_ETHEREUM]: 'https://optimistic.etherscan.io/',
  [SupportedChainId.CELO]: 'https://explorer.celo.org/mainnet/',
  [SupportedChainId.CRONOS]: 'https://cronoscan.com/',
  [SupportedChainId.ARBITRUM_ONE]: 'https://arbiscan.io/',
}

export enum ExplorerDataType {
  TRANSACTION = 'transaction',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block',
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export function getExplorerLink(chainId: number, data: string, type: ExplorerDataType): string {
  const prefix = ETHERSCAN_PREFIXES[chainId] ?? 'https://etherscan.io'

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${data}`

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${data}`

    case ExplorerDataType.BLOCK:
      return `${prefix}/block/${data}`

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${data}`
    default:
      return `${prefix}`
  }
}
