
import { AutoColumn } from 'components/Column'
import { AutoRow } from 'components/Row'
import { CHAIN_INFO } from 'constants/chainInfo'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

const MobileWrapper = styled(AutoColumn)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.bg3)};
  border-radius: 10px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.bg2};
  }

  color: ${({ theme, disable }) => disable && theme.text3};
  background-color: ${({ theme, disable }) => disable && theme.bg3};
  filter: ${({ disable }) => disable && 'grayscale(1)'};
`


export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
  otherSelectedCurrency
}: {
  otherSelectedCurrency?:any
  chainId?: number
  selectedCurrency?: any
  onSelect: (currency: any) => void
}) {
  const bases = typeof chainId !== 'undefined' ? CHAIN_INFO[Number(chainId)].bridgeCurrency ?? [] : []
  return bases.length > 0 ? (
    <MobileWrapper gap="md">
      <AutoRow gap="4px">
        {bases.map((currency: any) => {
          const isSelected = selectedCurrency?.address===currency?.address||otherSelectedCurrency?.address===currency?.address
          return (
            <>
         
            <BaseWrapper
            onClick={() => !isSelected && onSelect(currency)}
            disable={isSelected}
            key={currency?.symbol}
            >
              <img style={{width:25, marginRight:'10px',borderRadius:'50%'}} src={currency.logoURI} />
              <Text fontWeight={500} fontSize={16}>
                {currency?.symbol}
              </Text>
            </BaseWrapper>
           
        </>
          )
        })}
      </AutoRow>
    </MobileWrapper>
    ) : null
}

