import { Currency, Price } from '@uniswap/sdk-core'
import { useCallback, useContext } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'

import theme from '../../lib/theme'

interface TradePriceProps {
  price: number
}

const StyledPriceContainer = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  grid-template-columns: 1fr auto;
  grid-gap: 0.25rem;
  display: flex;
  flex-direction: row;
  text-align: left;
  flex-wrap: wrap;
  padding: 8px 0;
  user-select: text;
`

export default function TradePrice({ price }: TradePriceProps) {
  let formattedPrice: string
  try {
    formattedPrice = price?.toFixed()
  } catch (error) {
    formattedPrice = '0'
  }

  const text = formattedPrice

  const theme = useContext(ThemeContext)

  return (
    <StyledPriceContainer title={text}>
      <Text color={theme.text3} fontWeight={600} fontSize={16} style={{ display: 'inline' }}>
        {text}
      </Text>{' '}
      {/*{usdcPrice && (*/}
      {/*  <ThemedText.DarkGray>*/}
      {/*    <span>(${usdcPrice.toSignificant(6, { groupSeparator: ',' })})</span>*/}
      {/*  </ThemedText.DarkGray>*/}
      {/*)}*/}
    </StyledPriceContainer>
  )
}
