import { AutoRow } from '../Row'
import BigNumber from 'bignumber.js'
import { BridgeCallbackError } from './styleds'
import { ButtonError } from '../Button'
import { ReactNode } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

export default function BridgeModalFooter({
  onConfirm,
  bridgeErrorMessage,
  outdatedRoutes,
  isSwapping,
  trade,
  disabledConfirm,
}: {
  trade: any
  outdatedRoutes: boolean
  onConfirm: () => void
  isSwapping: any
  bridgeErrorMessage: ReactNode | undefined
  disabledConfirm: boolean
}) {
  return (
    <>
      {isSwapping ? null : (
        <AutoRow>
          <ButtonError
            onClick={onConfirm}
            disabled={outdatedRoutes}
            style={{ margin: '10px 0 0 0' }}
            id="confirm-bridge-or-send"
          >
            <Text fontSize={20} fontWeight={500}>
              <span>Confirmar Swap</span>
            </Text>
          </ButtonError>
          {bridgeErrorMessage ? <BridgeCallbackError error={bridgeErrorMessage} /> : null}
        </AutoRow>
      )}
    </>
  )
}
