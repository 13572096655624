import Card, { OutlineCard } from 'components/Card'
import { ChevronDown, Info } from 'react-feather'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { HideSmall, ThemedText } from 'theme'
import Row, { RowBetween, RowFixed } from 'components/Row'
import styled, { keyframes, useTheme } from 'styled-components/macro'

import { AdvancedSwapDetails } from './AdvancedSwapDetails'
import AnimatedDropdown from 'components/AnimatedDropdown'
import { AutoColumn } from 'components/Column'

import { LoadingOpacityContainer } from 'components/Loader/styled'
import { MouseoverTooltipContent } from 'components/Tooltip'
import { ResponsiveTooltipContainer } from './styleds'
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/chains'
import { darken } from 'polished'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useDarkModeManager } from 'state/user/hooks'
import { useState } from 'react'

const Wrapper = styled(Row)`
  width: 100%;
  max-width: 470px;
  justify-content: center;
`

const StyledInfoIcon = styled(Info)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
  color: ${({ theme }) => theme.text3};
`

const StyledCard = styled(OutlineCard)<{ trade: any }>`
  padding: 12px;
  position: relative;
  overflow: hidden;
  height: ${({ trade }) => (trade ? '90px' : '0px')};
  transition: height 0.5s;
  width: 84%;
  border-radius: 0px 0px 12px 12px;
  justify-self: center;
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bg1};
`

const StyledHeaderRow = styled(RowBetween)<{ disabled: boolean; open: boolean }>`
  padding: 4px 8px;
  border-radius: 12px;
  background-color: ${({ open, theme }) => (open ? theme.bg1 : 'transparent')};
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  min-height: 40px;

  :hover {
    background-color: ${({ theme, disabled }) => (disabled ? theme.bg1 : darken(0.015, theme.bg1))};
  }
`

const RotatingArrow = styled(ChevronDown)<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
`

const StyledPolling = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin-right: 2px;
  margin-left: 10px;
  align-items: center;
  color: ${({ theme }) => theme.text1};
  transition: 250ms ease color;
`

const StyledPollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.bg2};
  transition: 250ms ease background-color;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.text1};
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  transition: 250ms ease border-color;
  left: -3px;
  top: -3px;
`

interface SwapDetailsInlineProps {
  trade?: any
  loading?: boolean
  showInverted?: boolean
  setShowInverted?: React.Dispatch<React.SetStateAction<boolean>>
  allowedSlippage?: Percent
}

export default function SwapDetailsDropdown({
  trade,

  allowedSlippage,
}: SwapDetailsInlineProps) {


  return (
    <Wrapper>
      <AutoColumn gap={'8px'} style={{ width: '100%', marginBottom: '0px', marginTop: '-50px' }}>
        <AutoColumn gap={'8px'} style={{ padding: '0', paddingBottom: '8px' }}>
          <StyledCard trade={trade}>
            <AdvancedSwapDetails isSwap={true} trade={trade} allowedSlippage={allowedSlippage} />
          </StyledCard>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  )
}

export function PriceDetails({
  trade,
  loading,
}: SwapDetailsInlineProps) {

  const [darkMode] = useDarkModeManager()

  const [showDetails, setShowDetails] = useState(true)
  if (trade) {
    trade.executionPrice = trade?.fromAmount / trade?.toAmount
  }
  if (loading) {
    return (
      <Wrapper>
        <AutoColumn gap={'8px'} style={{ width: '100%', marginBottom: '3px' }}>
          <StyledHeaderRow
            style={darkMode ? { position: 'relative', background: '#181818' } : {}}
            disabled={!trade}
            open={showDetails}
          >
            <RowFixed style={darkMode ? { position: 'relative', background: '#181818' } : { position: 'relative' }}>
              {loading ? (
                <StyledPolling>
                  <StyledPollingDot>
                    <Spinner />
                  </StyledPollingDot>
                </StyledPolling>
              ) : null}
              {loading ? (
                <ThemedText.Main fontSize={14}>
                  <span>Buscando mejor ruta...</span>
                </ThemedText.Main>
              ) : null}
            </RowFixed>
          </StyledHeaderRow>
        </AutoColumn>
      </Wrapper>
    )
  }
  return null
}
