import { AlertTriangle, ArrowDown } from 'react-feather'
import { AutoColumn, ColumnCenter } from '../Column'
import { BridgeShowAcceptChanges, TruncatedText } from './styleds'
import {  CustomLightSpinner } from '../../theme'
import {  Percent, TradeType } from '@uniswap/sdk-core'
import { RowBetween, RowFixed } from '../Row'
import styled, { ThemeContext } from 'styled-components/macro'
import { useContext } from 'react'

import { ButtonPrimary } from '../Button'
import { CHAIN_INFO } from 'constants/chainInfo'
import Circle from '../../assets/images/blue-loader.svg'
import { Text } from 'rebass'
import { ThemedText } from '../../theme'
import { formatDecimals } from 'utils/formatCurrencyAmount'
import moment from 'moment'

const ArrowWrapper = styled.div`
  padding: 4px;
  border-radius: 12px;
  height: 32px;
  width: 32px;
  margin-top: -18px;
  margin-bottom: -18px;
  margin-left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bg0};
  border: 4px solid;
  border-color: ${({ theme }) => theme.bg0};
  z-index: 2;
`
const DivFlex = styled.div`
  display: flex;
`

const Label = styled.p`
  width: 100%;
  margin: 2px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #565a69;
`
const Result = styled.p`
  font-weight: 500;
  margin: 2px 0px;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #000000;
  width: 100%;
`
const Estimate = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #828798;
  padding: 0px 10px;
`
const ChainIdentificator = styled.p`
  margin: 0px;
  margin-left: 55px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a5a5a5;
`
const Waiting = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
`
const Confirm = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #828798;
  margin-top: 35px;
`
const Transfer = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-top: 5px;
`
const ConfirmedIcon = styled(ColumnCenter)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '20px 0' : '32px 0;')};
`

export default function BridgeModalHeader({
  trade,
  allowedSlippage,
  routesLoading,
  outdatedRoutes,
  showAcceptChanges,
  onAcceptChanges,
  isSwapping,
}: {
  trade: any
  routesLoading: boolean
  outdatedRoutes: boolean
  isSwapping: any
  allowedSlippage: Percent
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const theme = useContext(ThemeContext)
  if (isSwapping) {
    return (
      <>
        <ConfirmedIcon>
          <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={500} fontSize={20} textAlign="center">
            <span>Waiting For Confirmation</span>
          </Text>
          <Text fontWeight={400} fontSize={16} textAlign="center"></Text>
          <Text fontWeight={500} fontSize={14} color="#565A69" textAlign="center" marginBottom="12px">
            <span>Confirm this transaction in your wallet</span>
          </Text>
        </AutoColumn>
      </>
    )
  } else {
    return (
      <AutoColumn gap={'4px'} style={{ marginTop: '1rem' }}>
        <AutoColumn style={{ marginBottom: '1rem' }} gap={'8px'}>
          <RowBetween align="center">
            <RowFixed gap={'0px'}>
              <TruncatedText
                fontSize={24}
                fontWeight={500}
                color={showAcceptChanges && trade?.tradeType === TradeType.EXACT_OUTPUT ? theme.primary1 : ''}
              >
                <img
                  src={
                    trade?.result.fromToken.symbol === 'WETH'
                      ? 'https://static.debank.com/image/token/logo_url/eth/935ae4e4d1d12d59a99717a24f2540b5.png'
                      : trade?.result.fromToken.symbol === 'USDC'
                      ? 'https://static.debank.com/image/eth_token/logo_url/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48/fffcd27b9efff5a86ab942084c05924d.png'
                      : trade?.result.fromToken.symbol === 'USDT'
                      ? 'https://static.debank.com/image/eth_token/logo_url/0xdac17f958d2ee523a2206206994597c13d831ec7/66eadee7b7bb16b75e02b570ab8d5c01.png'
                      : trade?.result.fromToken?.logoURI
                  }
                  style={{ marginRight: '12px', width: '25px', borderRadius: '50%' }}
                />

                <span>{formatDecimals(trade?.result?.fromToken, trade?.result?.fromAmount)}</span>
              </TruncatedText>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <Text fontSize={20} fontWeight={500}>
                {CHAIN_INFO[Number(trade?.result.fromChainId)].label}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween></RowBetween>
        </AutoColumn>
        <ArrowWrapper>
          <ArrowDown size="16" color={theme.text2} />
        </ArrowWrapper>
        <AutoColumn style={{ marginTop: '1.4rem', marginBottom: '1rem' }} gap={'8px'}>
          <RowBetween align="flex-end">
            <RowFixed gap={'0px'}>
              <img
                src={
                  trade?.result.toToken?.symbol === 'WETH'
                    ? 'https://static.debank.com/image/token/logo_url/eth/935ae4e4d1d12d59a99717a24f2540b5.png'
                    : trade?.result.toToken?.symbol === 'USDC'
                    ? 'https://static.debank.com/image/eth_token/logo_url/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48/fffcd27b9efff5a86ab942084c05924d.png'
                    : trade?.result.toToken?.symbol === 'USDT'
                    ? 'https://static.debank.com/image/eth_token/logo_url/0xdac17f958d2ee523a2206206994597c13d831ec7/66eadee7b7bb16b75e02b570ab8d5c01.png'
                    : trade?.result.toToken?.logoURI
                }
                style={{ marginRight: '12px', width: '25px', borderRadius: '50%' }}
              />
              <TruncatedText fontSize={24} fontWeight={500}>
                <span>{formatDecimals(trade?.result?.toToken, trade?.result?.toAmount)}</span>
              </TruncatedText>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <Text fontSize={20} fontWeight={500}>
                {CHAIN_INFO[Number(trade?.result.toChainId)].label}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <ThemedText.Body fontSize={14} color={theme.text3}></ThemedText.Body>
          </RowBetween>
        </AutoColumn>
        {moment().subtract(1, 'minute').isAfter(moment(trade.date)) ? (
          <BridgeShowAcceptChanges justify="flex-start" gap={'0px'}>
            <RowBetween>
              <RowFixed>
                <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} />
                <ThemedText.Main color={theme.primary1}>
                  <span>Precio Actualizado</span>
                </ThemedText.Main>
              </RowFixed>
              <ButtonPrimary
                style={{ padding: '.5rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '12px' }}
                onClick={onAcceptChanges}
                disabled={routesLoading}
              >
                <span>Accept</span>
              </ButtonPrimary>
            </RowBetween>
          </BridgeShowAcceptChanges>
        ) : null}

        <ThemedText.Italic fontWeight={400} textAlign="left" style={{ width: '100%', padding: '5px 20px' }}>
          {CHAIN_INFO[Number(trade?.result.fromChainId)].nativeCurrency.address === trade?.result?.fromToken.address ? (
            <span>
              <b>Caution:</b> you may receive stablecoin on destination chain if transaction reverts midway.
            </span>
          ) : null}
        </ThemedText.Italic>
      </AutoColumn>
    )
  }
}
