// eslint-disable-next-line no-restricted-imports

import { ButtonText, CloseIcon, ThemedText } from '../../theme'
import { Currency, Token } from '@uniswap/sdk-core'
import {  RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { PaddedColumn, SearchInput, Separator } from './styleds'
import Row, { RowBetween, RowFixed } from '../Row'

import AutoSizer from 'react-virtualized-auto-sizer'
import { CHAIN_IDS_TO_NAMES } from 'constants/chains'
import Column from '../Column'
import CommonBases from './CommonBases'
import CurrencyList from './CurrencyList'
import { FixedSizeList } from 'react-window'
import ImportRow from './ImportRow'
import LiFi from 'components/LiFi'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import _ from 'lodash'
import { isAddress } from '../../utils'
import styled from 'styled-components/macro'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useDebounce from 'hooks/useDebounce'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useTheme from 'hooks/useTheme'
import useToggle from 'hooks/useToggle'

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`

const Footer = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: ${({ theme }) => theme.bg0};
  border-top: 1px solid ${({ theme }) => theme.bg2};
`
const List = styled.div`
  flex: 1;
`


export function CurrencySearch({
  selectedCurrency,
  balances1,
  tokens,
  onCurrencySelect,
  otherSelectedCurrency,
  showCurrencyAmount,
  onDismiss,
  isOpen,
  showManageView,
  showImportView,
  setImportToken,
}: any) {
  const { chainId } = useActiveWeb3React()
  const theme = useTheme()
  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [token, setToken] = useState('')

  const [isFiltering, setIsFiltering] = useState<boolean>(false)
  const debouncedQuery = useDebounce(searchQuery, 200)
  const [filtered, setFiltered] = useState<any>()
  const [address, setAddress] = useState<any>()

  // if they input an address, use it
  const isAddressSearch = isAddress(debouncedQuery)



  useEffect(() => {
    setFiltered(tokens)
  }, [tokens])

  useEffect(() => {
    if (isAddressSearch) {
      ReactGA.event({
        category: 'Currency Select',
        action: 'Search by address',
        label: isAddressSearch,
      })
    }
  }, [isAddressSearch])

  const handleCurrencySelect = useCallback(
    (currency: Token) => {
      onCurrencySelect(currency)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen])
  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback(
    (event:any) => {
      setIsFiltering(true)
      const isAddressSearch = isAddress(event.target.value)
      if (isAddressSearch) {
        setAddress(isAddressSearch)
      }
      if (!isFiltering && tokens.length) {
        const tokens2 = tokens && tokens?.filter((tok: any) => tok?.symbol.match(new RegExp(event.target.value, 'i')))
        setFiltered(tokens && [...tokens2])
        fixedList.current?.scrollTo(0)
        setIsFiltering(false)
      }
    },
    [filtered, tokens]
  )

  useEffect(() => {
    if (address) {
      LiFi.getToken(Number(chainId), address).then((updatedToken: any) => {
        setToken(updatedToken)
      })
    }
  }, [address])

  const debounced = (event: any) => {
    handleInput(event)
  }
  // menu ui
  const [open, toggle] = useToggle(false)
  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, open ? toggle : undefined)
  // if no results on main list, show option to expand into inactive
  return (
    <ContentWrapper>
      <PaddedColumn gap="16px">
        <RowBetween>
          <Text></Text>
          <Text fontWeight={500} fontSize={16}>
            <span>selecciona un token</span>
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <Row>
          <SearchInput
            type="text"
            id="token-search-input"
            placeholder={`Search name or paste address`}
            autoComplete="off"
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={(e) => debounced(e)}
            onKeyDown={() => {
              return
            }}
          />
        </Row>
        <CommonBases
          otherSelectedCurrency={otherSelectedCurrency}
          chainId={chainId}
          onSelect={handleCurrencySelect}
          selectedCurrency={selectedCurrency}
        />
      </PaddedColumn>
      <Separator />

      {address ? (
        <Column style={{ padding: '20px 0', height: '100%' }}>
          <ImportRow token={token} showImportView={showImportView} setImportToken={setImportToken} />
        </Column>
      ) : filtered?.length > 0 ? (
        <List>
           <AutoSizer disableWidth> 
            {({ height }:any) => (
              <CurrencyList
                balances1={balances1}
                height={height}
                currencies={filtered}
                chain={CHAIN_IDS_TO_NAMES[Number(chainId)]}
                onCurrencySelect={handleCurrencySelect}
                otherCurrency={otherSelectedCurrency}
                selectedCurrency={selectedCurrency}
                fixedListRef={fixedList}
                showImportView={showImportView}
                setImportToken={setImportToken}
                showCurrencyAmount={showCurrencyAmount}
              />
            )} 
           </AutoSizer>
        </List>
      ) : (
        <Column style={{ padding: '20px', height: '100%' }}>
          <ThemedText.Main color={theme.text3} textAlign="center" mb="20px">
            <span>No hay resultados</span>
          </ThemedText.Main>
        </Column>
      )}
      <Footer>
        <Row justify="center">
          <ButtonText onClick={showManageView} color={theme.primary1} className="list-token-manage-button">
            <RowFixed>
              <ThemedText.Main color={theme.primaryText1}>
                <span>Manejar lista de tokens</span>
              </ThemedText.Main>
            </RowFixed>
          </ButtonText>
        </Row>
      </Footer>
    </ContentWrapper>
  )
}
