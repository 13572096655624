import { AlertTriangle, ArrowDown } from 'react-feather'
import {  Percent, TradeType } from '@uniswap/sdk-core'
import { RowBetween, RowFixed } from '../Row'
import { SwapShowAcceptChanges, TruncatedText } from './styleds'
import styled, { ThemeContext } from 'styled-components/macro'
import { useContext } from 'react'

import { AutoColumn } from '../Column'
import { ButtonPrimary } from '../Button'

import { Text } from 'rebass'
import { ThemedText } from '../../theme'

import { formatDecimals } from 'utils/formatCurrencyAmount'
import moment from 'moment'

const ArrowWrapper = styled.div`
  padding: 4px;
  border-radius: 12px;
  height: 32px;
  width: 32px;
  margin-top: -18px;
  margin-bottom: -18px;
  margin-left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bg0};
  border: 4px solid;
  border-color: ${({ theme }) => theme.bg0};
  z-index: 2;
`
const InnerWrapper = styled.div`
  padding: 1rem;
`


export default function SwapModalHeader({
  trade,
  routesLoading,
  showAcceptChanges,
  onAcceptChanges,
}: any) {
  const theme = useContext(ThemeContext)
  const swapSlippage: any = localStorage.getItem('sSlipagge') || 0.5
  let result: any
  if (trade?.result?.toAmountMin !== trade?.result?.toAmount) {
    result = formatDecimals(trade?.result?.toToken, trade?.result?.toAmountMin)
  } else {
    const minimum = formatDecimals(trade?.result?.toToken, trade?.result?.toAmountMin)
    result = minimum - minimum * (swapSlippage / 100)
  }
  return (
    <AutoColumn gap={'4px'} style={{ marginTop: '1rem' }}>
      <InnerWrapper>
        <AutoColumn style={{ marginBottom: '1rem' }} gap={'8px'}>
          <RowBetween align="center">
            <RowFixed gap={'0px'}>
              <TruncatedText
                fontSize={24}
                fontWeight={500}
                color={showAcceptChanges && trade?.tradeType === TradeType.EXACT_OUTPUT ? theme.primary1 : ''}
              >
                <img
                  src={trade?.result?.fromToken?.logoURI}
                  style={{ marginRight: '12px', width: '25px', borderRadius: '50%' }}
                />

                {formatDecimals(trade?.result?.fromToken, trade?.result?.fromAmount)}
              </TruncatedText>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <Text fontSize={20} fontWeight={500}>
                {trade?.result?.fromToken?.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween></RowBetween>
        </AutoColumn>
        <ArrowWrapper>
          <ArrowDown size="16" color={theme.text2} />
        </ArrowWrapper>
        <AutoColumn style={{ marginTop: '1.4rem', marginBottom: '1rem' }} gap={'8px'}>
          <RowBetween align="flex-end">
            <RowFixed gap={'0px'}>
              <img
                src={trade?.result?.toToken?.logoURI}
                style={{ marginRight: '12px', width: '25px', borderRadius: '50%' }}
              />
              <TruncatedText fontSize={24} fontWeight={500}>
                {formatDecimals(trade?.result?.toToken, trade?.result?.toAmount)}
              </TruncatedText>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <Text fontSize={20} fontWeight={500}>
                {trade?.result?.toToken.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <ThemedText.Body fontSize={14} color={theme.text3}></ThemedText.Body>
          </RowBetween>
        </AutoColumn>

        <RowBetween style={{ marginTop: '0.25rem', padding: '0 1rem' }}>
        </RowBetween>
        <ThemedText.Italic fontWeight={400} textAlign="left" style={{ width: '100%' }}>
          <span>
           El output es estimado. Al menos recibiras{' '}
            <b>
              {trade?.result?.toAmountMin !== trade?.result?.toAmount ? result : Number(result?.toFixed(5))}{' '}
              {trade?.result?.toToken.symbol}
            </b>{' '}
            o la transaccion se revertira.
          </span>
        </ThemedText.Italic>
      </InnerWrapper>
      {moment().subtract(1, 'minute').isAfter(moment(trade.date)) ? (
        <SwapShowAcceptChanges justify="flex-start" gap={'0px'}>
          <RowBetween>
            <RowFixed>
              <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} />
              <ThemedText.Main color={theme.primary1}>
                <span>Precio Actualizado</span>
              </ThemedText.Main>
            </RowFixed>
            <ButtonPrimary
              style={{ padding: '.5rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '12px' }}
              onClick={onAcceptChanges}
              disabled={routesLoading}
            >
              <span>Aceptar</span>
            </ButtonPrimary>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
    </AutoColumn>
  )
}
