import { Currency, Token } from '@uniswap/sdk-core'
import { TokenAddressMap, useUnsupportedTokenList } from './../state/lists/hooks'
import { useAllLists, useCombinedActiveList, useInactiveListUrls } from '../state/lists/hooks'
import { useCurrencyFromMap, useTokenFromMap } from 'lib/hooks/useCurrency'


import { SupportedChainId } from 'constants/chains'
import { USDC_BSC } from '../constants/tokens'
import { WrappedTokenInfo } from '../state/lists/wrappedTokenInfo'
import { getTokenFilter } from 'lib/hooks/useTokenList/filtering'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMemo } from 'react'
import { useUserAddedTokens } from '../state/user/hooks'

// reduce token map into standard address <-> Token mapping, optionally include user added tokens
function useTokensFromMap(
  tokenMap: TokenAddressMap,
  includeUserAdded: boolean,
  outputNetworkId?: number | null
): { [address: string]: Token } {
  const { chainId } = useActiveWeb3React()
  const userAddedTokens = useUserAddedTokens()

  const networkId = outputNetworkId ?? chainId

  return useMemo(() => {
    if (!networkId) return {}

    // reduce to just tokens
    const mapWithoutUrls = Object.keys(tokenMap[networkId] ?? {}).reduce<{ [address: string]: Token }>(
      (newMap, address) => {
        newMap[address] = tokenMap[networkId][address].token
        return newMap
      },
      {}
    )

    if (includeUserAdded) {
      return (
        userAddedTokens
          // reduce into all ALL_TOKENS filtered by the current chain
          .reduce<{ [address: string]: Token }>(
            (tokenMap, token) => {
              tokenMap[token.address] = token
              return tokenMap
            },
            // must make a copy because reduce modifies the map, and we do not
            // want to make a copy in every iteration
            { ...mapWithoutUrls }
          )
      )
    }

    return mapWithoutUrls
  }, [networkId, userAddedTokens, tokenMap, includeUserAdded])
}

export function useAllTokens(): { [address: string]: Token } {
  const allTokens = useCombinedActiveList()
  return useTokensFromMap(allTokens, true)
}

export const cBridgeTokens: TokenAddressMap = {
  1: {
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': {
      token: new WrappedTokenInfo({
        chainId: 1,
        address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        name: 'USD Coin',
        decimals: 6,
        symbol: 'USDC',
        logoURI: 'https://get.celer.app/cbridge-icons/USDC.png',
      }),
    },
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2': {
      token: new WrappedTokenInfo({
        chainId: 1,
        address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        name: 'Wrapped ETH',
        decimals: 18,
        symbol: 'WETH',
        logoURI: 'https://get.celer.app/cbridge-icons/ETH.png',
      }),
    },
  },
  43114: {
    '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664': {
      token: new WrappedTokenInfo({
        chainId: SupportedChainId.AVALANCHE_MAINNET,
        address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
        name: 'USD Coin',
        decimals: 6,
        symbol: 'USDC',
        logoURI: 'https://get.celer.app/cbridge-icons/USDC.png',
      }),
    },
    '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB': {
      token: new WrappedTokenInfo({
        chainId: SupportedChainId.AVALANCHE_MAINNET,
        address: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
        name: 'Wrapped ETH',
        decimals: 18,
        symbol: 'WETH',
        logoURI: 'https://get.celer.app/cbridge-icons/ETH.png',
      }),
    },
  },
  137: {
    '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': {
      token: new WrappedTokenInfo({
        chainId: SupportedChainId.POLYGON,
        address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        name: 'USD Coin',
        decimals: 6,
        symbol: 'USDC',
        logoURI: 'https://get.celer.app/cbridge-icons/USDC.png',
      }),
    },
    '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619': {
      token: new WrappedTokenInfo({
        chainId: SupportedChainId.POLYGON,
        address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        name: 'Wrapped ETH',
        decimals: 18,
        symbol: 'WETH',
        logoURI: 'https://get.celer.app/cbridge-icons/ETH.png',
      }),
    },
  },
  250: {
    '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75': {
      token: new WrappedTokenInfo({
        chainId: SupportedChainId.FANTOM_OPERA_MAINNET,
        address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
        name: 'USD Coin',
        decimals: 6,
        symbol: 'USDC',
        logoURI: 'https://get.celer.app/cbridge-icons/USDC.png',
      }),
    },
    '0x74b23882a30290451A17c44f4F05243b6b58C76d': {
      token: new WrappedTokenInfo({
        chainId: SupportedChainId.FANTOM_OPERA_MAINNET,
        address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
        name: 'Wrapped ETH',
        decimals: 18,
        symbol: 'WETH',
        logoURI: 'https://get.celer.app/cbridge-icons/ETH.png',
      }),
    },
  },
  
  56: {
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d': {
      token: new WrappedTokenInfo({
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        name: 'Binance-Peg USD Coin',
        decimals: 18,
        symbol: 'USDC',
        logoURI: 'https://get.celer.app/cbridge-icons/USDC.png',
      }),
    },
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8': {
      token: new WrappedTokenInfo({
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        name: 'Binance-Peg Ethereum Token',
        decimals: 18,
        symbol: 'WETH',
        logoURI: 'https://get.celer.app/cbridge-icons/ETH.png',
      }),
    },
  },
}
export function useCBridgeTokens(outputNetworkId?: number | null): { [address: string]: Token } {
  return useTokensFromMap(cBridgeTokens, true, outputNetworkId)
}

export const cBridgeDestinationToken = (destinationNetwork?: number | null, symbol?: string | null) =>
  destinationNetwork
    ? Object.values(cBridgeTokens[destinationNetwork]).filter((i) => i.token.tokenInfo.symbol === symbol ?? 'USDC')[0]
        ?.token
    : USDC_BSC

type BridgeInfo = Record<
  SupportedChainId,
  {
    tokenAddress: string
    originBridgeAddress: string
    destBridgeAddress: string
  }
>

export function useUnsupportedTokens(): { [address: string]: Token } {
  const { chainId } = useActiveWeb3React()
  const listsByUrl = useAllLists()
  const unsupportedTokensMap = useUnsupportedTokenList()
  const unsupportedTokens = useTokensFromMap(unsupportedTokensMap, false)

  // checks the default L2 lists to see if `bridgeInfo` has an L1 address value that is unsupported
  // const l2InferredBlockedTokens: typeof unsupportedTokens = useMemo(() => {
  //   // if (!chainId || !L2_CHAIN_IDS.includes(chainId)) {
  //   if (!chainId) {
  //     return {}
  //   }
  //
  //   if (!listsByUrl) {
  //     return {}
  //   }
  //
  //   const listUrl = CHAIN_INFO[chainId as SupportedL2ChainId].defaultListUrl
  //   const { current: list } = listsByUrl[listUrl]
  //   if (!list) {
  //     return {}
  //   }
  //
  //   const unsupportedSet = new Set(Object.keys(unsupportedTokens))
  //
  //   return list.tokens.reduce((acc, tokenInfo) => {
  //     const bridgeInfo = tokenInfo.extensions?.bridgeInfo as unknown as BridgeInfo
  //     if (
  //       bridgeInfo &&
  //       bridgeInfo[SupportedChainId.MAINNET] &&
  //       bridgeInfo[SupportedChainId.MAINNET].tokenAddress &&
  //       unsupportedSet.has(bridgeInfo[SupportedChainId.MAINNET].tokenAddress)
  //     ) {
  //       const address = bridgeInfo[SupportedChainId.MAINNET].tokenAddress
  //       // don't rely on decimals--it's possible that a token could be bridged w/ different decimals on the L2
  //       return { ...acc, [address]: new Token(SupportedChainId.MAINNET, address, tokenInfo.decimals) }
  //     }
  //     return acc
  //   }, {})
  // }, [chainId, listsByUrl, unsupportedTokens])

  // return { ...unsupportedTokens, ...l2InferredBlockedTokens }
  return { ...unsupportedTokens }
}

export function useSearchInactiveTokenLists(search: string | undefined, minResults = 10): WrappedTokenInfo[] {
  const lists = useAllLists()
  const inactiveUrls = useInactiveListUrls()
  const { chainId } = useActiveWeb3React()
  const activeTokens = useAllTokens()
  return useMemo(() => {
    if (!search || search.trim().length === 0) return []
    const tokenFilter = getTokenFilter(search)
    const result: WrappedTokenInfo[] = []
    const addressSet: { [address: string]: true } = {}
    for (const url of inactiveUrls) {
      const list = lists[url].current
      if (!list) continue
      for (const tokenInfo of list.tokens) {
        if (tokenInfo.chainId === chainId && tokenFilter(tokenInfo)) {
          const wrapped: WrappedTokenInfo = new WrappedTokenInfo(tokenInfo, list)
          if (!(wrapped.address in activeTokens) && !addressSet[wrapped.address]) {
            addressSet[wrapped.address] = true
            result.push(wrapped)
            if (result.length >= minResults) return result
          }
        }
      }
    }
    return result
  }, [activeTokens, chainId, inactiveUrls, lists, minResults, search])
}

export function useIsTokenActive(token: Token | undefined | null): boolean {
  const activeTokens = useAllTokens()

  if (!activeTokens || !token) {
    return false
  }

  return !!activeTokens[token.address]
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: any): boolean {
  const userAddedTokens = useUserAddedTokens()

  if (!currency) {
    return false
  }

  return !!userAddedTokens.find((token) => currency.address === token.address)
}

// undefined if invalid or does not exist
// null if loading or null was passed
// otherwise returns the token
export function useToken(tokenAddress?: string | null, tokens2?: any): Token | null | undefined {
  const tokens = useAllTokens()
  const sendTokens = tokens2 || tokens

  return useTokenFromMap(sendTokens, tokenAddress)
}

export function useCurrency(currencyId?: string | null): Currency | null | undefined {
  const tokens = useAllTokens()
  return useCurrencyFromMap(tokens, currencyId)
}

export function useCbridgeCurrency(
  currencyId?: string | null,
  outputNetworkId?: number | null
): Currency | null | undefined {
  const tokens = useCBridgeTokens(outputNetworkId)
  return useCurrencyFromMap(tokens, currencyId)
}
