import { AlertCircle, CheckCircle } from 'react-feather'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import styled, { ThemeContext } from 'styled-components/macro'

import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
import { ExternalLink } from '../../theme'
import { ThemedText } from '../../theme'
import { TransactionSummary } from '../AccountDetails/TransactionSummary'
import { info } from 'console'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContext } from 'react'
import { useTransaction } from '../../state/transactions/hooks'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

export default function TransactionPopup({ hash }: { hash: any }) {
  const { chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  // const success = Boolean(tx.receipt && tx.receipt.status === 1)
  if(hash?.failedSwitchNetwork){
    return(
    <RowNoFlex>
      <AlertCircle style={{marginRight:'10px'}} color={theme.red1} size={24} />
    <AutoColumn gap="8px">
      <ThemedText.Body fontWeight={500}>
        <TransactionSummary info={hash} />
      </ThemedText.Body>
    </AutoColumn>
  </RowNoFlex>
    )
  }else{
  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {true ? <CheckCircle color={theme.green1} size={24} /> : <AlertCircle color={theme.red1} size={24} />}
      </div>
      <AutoColumn gap="8px">
        <ThemedText.Body fontWeight={500}>
          <TransactionSummary info={hash} />
        </ThemedText.Body>
        {chainId && (
          <a rel="noopener noreferrer" target="_blank"  style={{textDecoration:'none'}} href={hash?.info?.excution[hash?.info?.excution.length-1].txLink}>
            Ver en Explorador
          </a>
        )}
      </AutoColumn>
    </RowNoFlex>
  )
}
}

