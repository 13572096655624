import { SupportedChainId } from './chains'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const INFURA_NETWORK_URLS: any = {
  [SupportedChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.GOERLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.POLYGON]: `https://polygon-rpc.com/`,
  [SupportedChainId.POLYGON_MUMBAI]: `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.AVALANCHE_MAINNET]: `https://api.avax.network/ext/bc/C/rpc`,
  [SupportedChainId.AVALANCHE_FUJI_TESTNET]: `https://speedy-nodes-nyc.moralis.io/a40c8caced78c766bbef672e/avalanche/testnet`,
  [SupportedChainId.BSC_MAINNET]: `https://bsc-dataseed.binance.org/`,
  [SupportedChainId.BSC_TESTNET]: `https://data-seed-prebsc-1-s1.binance.org:8545/`,
  [SupportedChainId.FANTOM_OPERA_MAINNET]: `https://rpc.ftm.tools/`,
  [SupportedChainId.FANTOM_OPERA_TESTNET]: `https://rpc.testnet.fantom.network/`,
  [SupportedChainId.GNOSIS]: `https://rpc.gnosischain.com/`,
  [SupportedChainId.ARBITRUM_ONE]: `https://arb1.arbitrum.io/rpc`,
  [SupportedChainId.OPTIMISTIC_ETHEREUM]: `https://mainnet.optimism.io/`,
  [SupportedChainId.CELO]: `https://forno.celo.org`,
  [SupportedChainId.CRONOS]: `https://evm-cronos.crypto.org`,
}
