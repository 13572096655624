import { Redirect } from 'react-router'

const DefaultRoute = () => {
  return (
    <Redirect to={'/swap'}/>
   )
}

export default DefaultRoute


 

