import { ArrowDown } from 'react-feather'
import { ButtonError, ButtonGray, ButtonLight } from '../../components/Button'
import { Chain, ChainKey, Route , Token, TokenAmount } from '../../types'
import { ExecutionSettings, RoutesResponse } from '@lifi/sdk'
import { shouldShowAlert } from 'components/NetworkAlert/NetworkAlert'
import { isWalletConnectWallet } from '../../components/services/localStorage'
import { switchChain } from '../../components/services/metamask'
import { useAllTransactions, useTransactionAdder } from 'state/transactions/hooks'
import { useCallback, useContext, useEffect, useState } from 'react'

import { useUserAddedTokens } from '../../state/user/hooks'

import AppBody from '../AppBody'
import { AutoColumn } from '../../components/Column'
import BigNumber from 'bignumber.js'
import { CHAIN_IDS_TO_NAMES } from 'constants/chains'
import { CHAIN_INFO } from 'constants/chainInfo'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'

import LiFi from '../../components/LiFi'
import Loader from '../../components/Loader'

import { ArrowWrapper, Wrapper } from '../../components/swap/styleds'

import moment from 'moment'
import { useActiveListUrls } from 'state/lists/hooks'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useAppSelector } from 'state/hooks'

import { useWalletModalToggle } from '../../state/application/hooks'
import { useWeb3React } from 'web3-react-core'
import { v4 as uuid } from 'uuid'
import { maintenanceMode } from 'pages/maintenance'
import { PriceDetails } from 'components/swap/SwapDetailsDropdown'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import { Text } from 'rebass'
import { hasSufficientBalance, hasSufficientGasBalanceOnStartChain } from 'utils/hasSufficentGas'

interface TokenWithAmounts extends Token {
  amount?: BigNumber
  amountRendered?: string
}

interface TokenAmountList {
  [ChainKey: string]: Array<TokenWithAmounts>
}

export default function Swap() {
  const { account, chainId } = useActiveWeb3React()
  const [depositAmount, setDepositAmount] = useState<any>()
  const [fromTokenAddress, setFromTokenAddress] = useState<any>(CHAIN_INFO[Number(chainId)]?.nativeCurrency)
  const [toTokenAddress, setToTokenAddress] = useState<any>()
  const [availableChains, setAvailableChains] = useState<Chain[]>([])

  const [tokens, setTokens] = useState<TokenAmountList>({})
  const [filtered, setFiltered] = useState<any>({})
  const [idRroute, setId] = useState<string>()
  const [refreshBalances, setRefreshBalances] = useState<boolean>(true)
  const [balances, setBalances] = useState<any>()
  const [isSwapping, setIsSwapping] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>()
  const [hasError, setHasError] = useState<boolean>()
  const [error, setError] = useState<any>()
  const [successfullyswapped, setSuccessfyllySwapped] = useState<string>()
  const [showMessage, setShowMessage] = useState<string>('Waiting for approval')
  const [showInverted, setShowInverted] = useState<boolean>(false)

  const [finalRoute, setFinalRoute] = useState<any>()
  const [noRoutesAvailable, setNoRoutesAvailable] = useState<boolean>(false)
  const [routesLoading, setRoutesLoading] = useState<boolean>(false)
  const [routeCallResult, setRouteCallResult] = useState<any>()

  const [isAllowing, setIsAllowing] = useState<boolean>()
  const [showConfirm, setShowConfirm] = useState<any>()

  const activeListUrls: string[]|undefined = useActiveListUrls()
  const web3 = useWeb3React()

  const toggleWalletModal = useWalletModalToggle()
  const listsByUrl = useAppSelector((state) => state.lists.byUrl)
  const updateBalances = useCallback(async () => {
    if (web3.account) {
      const newTokens = tokens
      if (filtered.length) {
        newTokens[CHAIN_IDS_TO_NAMES[Number(chainId)]] = [...filtered]
      }
      // one call per chain to show balances as soon as the request comes back
      Object.entries(newTokens).forEach(async ([chainKey, tokenList]) => {
        try {
          LiFi.getTokenBalances(web3.account!, tokenList).then((portfolio: any) => {
            setBalances((balances: TokenAmountList | null) => {
              if (!balances) balances = {}
              return {
                ...balances,
                [chainKey]: portfolio,
              }
            })
          })
        } catch (err) {
          throw new error()
        }
      })
    }
  }, [web3.account, tokens])

  const updateBalance = async () => {
    if (web3.account && balances && balances[CHAIN_IDS_TO_NAMES[Number(chainId)]]?.length) {
      const foundIndexFrom =
        balances &&
        balances[CHAIN_IDS_TO_NAMES[Number(chainId)]].findIndex(
          (token: Token) => token.address === fromTokenAddress.address
        )
      LiFi.getTokenBalance(web3.account!, fromTokenAddress).then((portfolio: any) => {
        setBalances((balances: any ) => {
          if (!balances||balances[CHAIN_IDS_TO_NAMES[Number(chainId)]].amount===0) return
          balances[CHAIN_IDS_TO_NAMES[Number(chainId)]][foundIndexFrom] = portfolio
          return {
            ...balances,
          }
        })
      })
      if (toTokenAddress.address) {
        const foundIndexTo =
          balances &&
          balances[CHAIN_IDS_TO_NAMES[Number(chainId)]].findIndex(
            (token: any) => token.address === toTokenAddress.address
          )
        LiFi.getTokenBalance(web3.account!, toTokenAddress).then((portfolio: any) => {
          setBalances((balances: any) => {
            if (!balances) balances = {}
            balances[CHAIN_IDS_TO_NAMES[Number(chainId)]][foundIndexTo] = portfolio
            return {
              ...balances,
            }
          })
        })
      }
    }
  }

  const allTransactions = useAllTransactions()

  useEffect(() => {
    if (refreshBalances && web3.account) {
      setRefreshBalances(false)
      updateBalances()
    }
  }, [refreshBalances, web3.account, updateBalances])

  useEffect(() => {
    setRefreshBalances(true)
  }, [allTransactions])

  useEffect(() => {
    setTimeout(() => {
      updateBalance()
      setRefresh(!refresh)
    }, 15000)
  }, [refresh])

  const handleSwitch = () => {
    if (!routesLoading) {
      const from = fromTokenAddress
      const to = toTokenAddress
      setFromTokenAddress(to)
      setToTokenAddress(from)
    }
  }

  const addTransaction = useTransactionAdder()

  const handleInputSelect = useCallback((inputCurrency:string) => {
    setFromTokenAddress(inputCurrency)
    setFinalRoute('')
  }, [])

  const handleOutputSelect = useCallback((outputCurrency:string) => {
    setFinalRoute('')
    setToTokenAddress(outputCurrency)
  }, [])

  useEffect(() => {
    const load = async () => {
      if (!availableChains.length || activeListUrls?.includes('lifi')) {
        const { tokens } = await LiFi.getTokens()
        const chains = await LiFi.getChains()

        setAvailableChains(chains)

        Object.keys(tokens).forEach((chainKey: string) => {
          if (CHAIN_IDS_TO_NAMES[chainKey]) {
            tokens[CHAIN_IDS_TO_NAMES[chainKey]] = tokens[Number(chainKey)]
            delete tokens[Number(chainKey)]
          } else {
            delete tokens[Number(chainKey)]
          }
        })

        setTokens(tokens)
        setRefreshBalances(true)
      }
    }
    load()
  }, [account, activeListUrls])

  useEffect(() => {
    if (CHAIN_INFO[Number(chainId)]?.nativeCurrency?.symbol !== fromTokenAddress?.symbol) {
      setFromTokenAddress(CHAIN_INFO[Number(chainId)]?.nativeCurrency)
    }
    setToTokenAddress('')
    setFinalRoute('')
    setDepositAmount('')
    setShowConfirm(false)
  }, [chainId])
  const userAddedTokens = useUserAddedTokens()

  useEffect(() => {
    if (!depositAmount) {
      setFinalRoute('')
      setRoutesLoading(false)
    }
  }, [depositAmount])

  useEffect(() => {
    if (!tokens) return
    setFiltered('')
    let toSetTokens: Token[] = []
    if (activeListUrls?.includes('lifi') && tokens[CHAIN_IDS_TO_NAMES[Number(chainId)]]?.length) {
      toSetTokens = [...tokens[CHAIN_IDS_TO_NAMES[Number(chainId)]]]
    }
    if (!activeListUrls?.includes('lifi')) {
      const current = tokens[CHAIN_IDS_TO_NAMES[Number(chainId)]]?.find(
        (tok: Token) => tok?.address === fromTokenAddress?.address
      )

      if (current) {
        toSetTokens = [...toSetTokens, current]
      }
    }
    if (activeListUrls) {
      let otherListsTokens: Token[] = []
      activeListUrls.forEach((listUrl: string) => {
        const list: any = listsByUrl[listUrl]
        if (list?.current) {
          if (list?.current?.tokens[0].chainId === chainId) {
            otherListsTokens = [...otherListsTokens, ...list?.current.tokens]
          }
        }
      })
      if (toSetTokens.length) {
        toSetTokens = [...toSetTokens, ...otherListsTokens]
      } else {
        toSetTokens = [...otherListsTokens]
      }
    }
    if (
      (userAddedTokens && !activeListUrls?.includes('lifi')) ||
      (userAddedTokens && tokens[CHAIN_IDS_TO_NAMES[Number(chainId)]]?.length)
    ) {
      if (toSetTokens) {
        const array: any[] = []
        userAddedTokens.forEach((element) => {
          const found = toSetTokens.find((tok: any) => tok?.address.toUpperCase() === element?.address.toUpperCase())
          if (found) return
          array.push(element)
        })
        toSetTokens = [...toSetTokens, ...array]
      }
    }

    if (toSetTokens.length) {
      setFiltered([...toSetTokens])
      setRefreshBalances(true)
    }
  }, [tokens, userAddedTokens, activeListUrls])

  const findToken = useCallback(
    (chainKey: ChainKey, tokenId: string) => {
      const token = filtered && filtered?.find((token: Token) => token.address === tokenId)
      return token
    },
    [tokens, filtered]
  )
  const getBalance = (
    currentBalances: { [ChainKey: string]: Array<TokenAmount> } | undefined,
    chainKey: any,
    tokenId: string
  ) => {
    if (!currentBalances || !currentBalances[chainKey]) {
      return new BigNumber(0)
    }
    const tokenBalance = currentBalances[chainKey].find((tokenAmount) => tokenAmount.address === tokenId)
    return tokenBalance?.amount ? new BigNumber(tokenBalance?.amount) : new BigNumber(0)
  }


  const status = localStorage.getItem('status')

  const slippage: string|null = localStorage.getItem('sSlipagge')
  const getRoutes = async () => {
   
    setSuccessfyllySwapped('')
    setNoRoutesAvailable(false)
    const isGreater = new BigNumber(depositAmount)
    if (isGreater.gt(0) && chainId && fromTokenAddress && toTokenAddress) {
      setRoutesLoading(true)
      if (filtered.length) {
        const fromToken = findToken(CHAIN_IDS_TO_NAMES[Number(chainId)], fromTokenAddress.address)
        const toToken = findToken(CHAIN_IDS_TO_NAMES[Number(chainId)], toTokenAddress.address)
        if (fromToken && toToken) {
          const request: any = {
            fromChainId: fromToken.chainId,
            fromAmount: new BigNumber(depositAmount).shiftedBy(fromToken.decimals).toFixed(0),
            fromTokenAddress: fromTokenAddress.address,
            toChainId: toToken.chainId,
            toTokenAddress: toTokenAddress.address,
            fromAddress: account || undefined,
            toAddress: account || undefined,
            options: {
              order: status || 'RECOMMENDED',
              slippage: Number(slippage) / 100,
              bridges: {
                allow: ['cbridge', 'connext', 'hop', 'hyphen', 'stargate', 'multichain', 'across'],
              },
            },
          }

          const id = uuid()
          try {
            setId(id)
            const result: RoutesResponse = await LiFi.getRoutes(request)
            if (!result.routes.length) {
              setRouteCallResult({ id })
            }

            const array = []
            const posibleArray = []
            for (let index = 0; index < result.routes.length; index++) {
              if (result.routes[index].steps.length > 1 || result.routes[index].steps[0].tool === '0x') {
              } else {
                if (status === 'RECOMMENDED') {
                  const firstStep = result.routes[index].steps[0].estimate
                  if ((Number(firstStep?.fromAmountUSD) * 100) / Number(firstStep?.toAmountUSD) >= 99.5) {
                    array.push({ result: result.routes[index], id, date: new Date() })
                  } else {
                    if (posibleArray.length) {
                      posibleArray.push({ result: result.routes[index], id, date: new Date() })
                    }
                  }
                } else {
                  array.push({ result: result.routes[index], id, date: new Date() })
                }
              }
            }
            if (array.length || posibleArray.length) {
              setRouteCallResult(array[0] || posibleArray[0])
            } else {
              if (id === idRroute) {
                setNoRoutesAvailable(true)
                setRoutesLoading(false)
              }
            }
          } catch {
            if (id === idRroute || !idRroute) {
              setNoRoutesAvailable(true)
              setRoutesLoading(false)
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    if (!routeCallResult?.result && routeCallResult?.id === idRroute) {
      setRoutesLoading(false)
      setNoRoutesAvailable(true)
    }
    if (routeCallResult) {
      const { result, id } = routeCallResult
      if (id === idRroute && depositAmount) {
        setFinalRoute(routeCallResult)
        setRoutesLoading(false)
      }
    }
  }, [routeCallResult])

  useEffect(() => {
    getRoutes()
  }, [fromTokenAddress, toTokenAddress, depositAmount, slippage, status])

  const updateCallback = (updatedRoute:Route) => {
    if (
      updatedRoute &&
      updatedRoute.steps[0].execution?.process[0]?.type === 'TOKEN_ALLOWANCE' &&
      updatedRoute &&
      updatedRoute?.steps[0].execution.process[0]?.status === 'DONE'
    ) {
      setShowMessage('Waiting for confirmation')
    }
    if (CHAIN_INFO[Number(chainId)]?.nativeCurrency.address === fromTokenAddress.address) {
      setShowMessage('Waiting for confirmation')
    }
    if (updatedRoute && updatedRoute?.steps[0]) {
      if (
        updatedRoute?.steps[0].execution?.process[0]?.type === 'SWAP' &&
        updatedRoute?.steps[0].execution.process[0]?.status === 'PENDING'
      ) {
        addTransaction({
          type: 1,
          id: updatedRoute.id,
          txLink: updatedRoute?.steps[0].execution.process[0].txLink,
          excution: updatedRoute?.steps[0].execution.process,
          tool: updatedRoute?.steps[0].tool,
          fromToken: updatedRoute.fromToken,
          toToken: updatedRoute.toToken,
          fromAmount: updatedRoute.fromAmount,
          toAmount: updatedRoute.toAmount,
          status: 'pending',
        })
        setSuccessfyllySwapped(updatedRoute?.steps[0].execution.process[0].txLink)
        setIsSwapping(false)
      }
    }
    if (
      updatedRoute?.steps[0].execution?.process[1]?.type === 'SWAP' &&
      updatedRoute?.steps[0].execution.process[1]?.status === 'PENDING'
    ) {
      addTransaction({
        type: 1,
        id: updatedRoute.id,
        txLink: updatedRoute?.steps[0].execution.process[1].txLink,
        excution: updatedRoute?.steps[0].execution.process,
        tool: updatedRoute?.steps[0].tool,
        fromToken: updatedRoute.fromToken,
        toToken: updatedRoute.toToken,
        fromAmount: updatedRoute.fromAmount,
        toAmount: updatedRoute.toAmount,
        status: 'pending',
      })
      setSuccessfyllySwapped(updatedRoute?.steps[0].execution.process[1].txLink)
      setIsSwapping(false)
    }
  }

  const switchChainHook = async (requiredChainId: number) => {
    if (!web3.account || !web3.library) return

    if (isWalletConnectWallet(web3.account)) {
      let promiseResolver
      const signerAwaiter = new Promise<void>((resolve) => (promiseResolver = resolve))

      await signerAwaiter
      return web3.library.getSigner()
    }

    if ((await web3.library.getSigner().getChainId()) !== requiredChainId) {
      // Fallback is Metamask
      const switched = await switchChain(requiredChainId)
      if (!switched) {
        throw new Error('Chain was not switched')
      }
    }
    return web3.library.getSigner()
  }

  const startCrossChainSwap = async () => {
    if (!web3.account || !web3.library) return
    const signer = web3.library.getSigner()
    const executionSettings: ExecutionSettings = {
      updateCallback,
      switchChainHook,
      infiniteApproval: true,
    }
    setIsSwapping(true)

    try {
      const tx = await LiFi.executeRoute(signer, finalRoute.result, { ...executionSettings })
    } catch (e) {
      // eslint-disable-next-line no-console
      setHasError(true)
      setError(e)
      console.warn('Execution failed!', finalRoute)
      console.error(e)
      setIsSwapping(false)
      return
    }
    setIsSwapping(false)
    updateBalances()
  }

  const handleMax = useCallback(() => {
    const tokenBalance = getBalance(balances, CHAIN_IDS_TO_NAMES[Number(chainId)], fromTokenAddress.address)
    if (tokenBalance.c && tokenBalance.c[0]) {
      setDepositAmount(tokenBalance)
    }
  }, [balances, CHAIN_IDS_TO_NAMES, fromTokenAddress])

  const hasSufficientBalanceResponse=hasSufficientBalance({fromTokenAddress,chainId,balances,depositAmount,getBalance})
  const hasSufficientGasBalanceOnStartChainResponse= finalRoute&& hasSufficientGasBalanceOnStartChain({finalRoute,balances,getBalance})
  
  return (
    <>
      {!shouldShowAlert(chainId) ? null : (
        <>
          <AppBody>
            <Wrapper id="swap-page">
              <ConfirmSwapModal
                outdatedRoutes={moment().subtract(1, 'minute').isAfter(moment(finalRoute?.date))}
                onAcceptChanges={() => getRoutes()}
                routesLoading={routesLoading}
                isOpen={showConfirm}
                hasError={hasError}
                message={showMessage}
                error={error}
                successfullyswapped={successfullyswapped}
                isSwapping={isSwapping}
                trade={finalRoute}
                onConfirm={startCrossChainSwap}
                onDismiss={() => {
                  if (successfullyswapped) {
                    setShowConfirm(false)
                    setHasError(false)
                    setError(false)
                    setSuccessfyllySwapped('')
                    setRouteCallResult('')
                    setFinalRoute('')
                    setDepositAmount('')
                    setShowMessage('Waiting for approval')
                  }
                  if (!isSwapping) {
                    setShowConfirm(false)
                    setHasError(false)
                    setShowMessage('Waiting for approval')
                    setError(false)
                    setSuccessfyllySwapped('')
                  }
                }}
              />

              <AutoColumn gap={'sm'}>
                <div style={{ display: 'relative' }}>
                  <CurrencyInputPanel
                    value={depositAmount}
                    currency={fromTokenAddress}
                    price={finalRoute}
                    balances={balances}
                    onUserInput={setDepositAmount}
                    tokens={filtered}
                    showMaxButton={true}
                    hideBalance={false}
                    onMax={handleMax}
                    outputNetwork={false}
                    fiatValue={undefined}
                    onCurrencySelect={handleInputSelect}
                    otherCurrency={toTokenAddress}
                    showCommonBases={true}
                    id="swap-currency-input"
                    loading={routesLoading}
                  />
                  <ArrowWrapper clickable>
                    <ArrowDown
                      size="16"
                      onClick={handleSwitch}
                    />
                  </ArrowWrapper>
                  <CurrencyInputPanel
                    value={''}
                    currency={toTokenAddress}
                    balances={balances}
                    trade={finalRoute}
                    price={finalRoute}
                    tokens={filtered}
                    availableChains={availableChains}
                    outputNetwork={true}
                    showMaxButton={false}
                    hideBalance={false}
                    fiatValue={undefined}
                    onCurrencySelect={handleOutputSelect}
                    otherCurrency={fromTokenAddress}
                    showCommonBases={true}
                    id="swap-currency-output"
                    loading={routesLoading}
                  />
                </div>

                {routesLoading || finalRoute ? (
                  <PriceDetails
                    trade={finalRoute?.result}
                    loading={routesLoading}
                    showInverted={showInverted}
                    setShowInverted={setShowInverted}
                  />
                ) : null}
                <div>
                  {maintenanceMode() ? (
                    <ButtonGray>
                      <span>Actualmente en mantenimiento</span>
                    </ButtonGray>
                  ) : isAllowing && !routesLoading ? (
                    <ButtonLight>
                      <Loader />
                    </ButtonLight>
                  ) : !account ? (
                    <ButtonLight onClick={toggleWalletModal}>
                      <span>Conecta tu billetera</span>
                    </ButtonLight>
                  ) : !depositAmount ? (
                    <ButtonGray>
                      <span>Ingresa una cantidad</span>
                    </ButtonGray>
                  ) : !toTokenAddress ? (
                    <ButtonGray>
                      <span>Selecciona un token</span>
                    </ButtonGray>
                  ) : noRoutesAvailable ? (
                    <ButtonGray>
                      <span>No hay rutas posibles</span>
                    </ButtonGray>
                  ) : finalRoute && !hasSufficientBalanceResponse ? (
                    <ButtonGray>
                      <span>Fondos insuficientes</span>
                    </ButtonGray>
                  ) : finalRoute && !hasSufficientGasBalanceOnStartChainResponse ? (
                    <ButtonGray>
                      <span>Gas insuficiente</span>
                    </ButtonGray>
                  ) : finalRoute &&
                    hasSufficientGasBalanceOnStartChainResponse &&
                    hasSufficientBalanceResponse &&
                    !routesLoading ? (
                    <ButtonError
                      onClick={() => {
                        setHasError(false)
                        setError(false)
                        setShowConfirm(true)
                      }}
                      id="swap-button"
                    >
                      <Text fontSize={20} fontWeight={500}>
                        <span>Swap</span>
                      </Text>
                    </ButtonError>
                  ) : null}
                </div>
              </AutoColumn>
            </Wrapper>
          </AppBody>
        </>
      )}
    </>
  )
}
