import '@reach/dialog/styles.css'
import 'inter-ui'
import 'polyfills'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { StrictMode } from 'react'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
import { Web3ReactProvider, createWeb3ReactRoot } from 'web3-react-core'
import * as ReactDOMClient from 'react-dom/client';
import App from './pages/App'
import { NetworkContextName } from './constants/misc'
import { Provider } from 'react-redux'
import getLibrary from './utils/getLibrary'
import store from './state'
import { BrowserRouter} from 'react-router-dom'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}



const root = ReactDOMClient.createRoot(document.getElementById("root") as HTMLElement);
root.render(
<StrictMode>
    <Provider store={store}>
    <BrowserRouter>    
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <ThemeProvider>
              <ThemedGlobalStyle />
              <App />
            </ThemeProvider>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
        </BrowserRouter>    
        </Provider>
  </StrictMode>
);

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  serviceWorkerRegistration.register()
}
