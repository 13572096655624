import {
  ChainKey,
  TokenAmount,
} from '../types'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import {  useCallback, useState } from 'react'
import { RowBetween, RowFixed } from '../Row'
import {  formatDecimals } from 'utils/formatCurrencyAmount'
import { AutoColumn } from 'components/Column'
import { CHAIN_IDS_TO_NAMES } from 'constants/chains'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import DoubleCurrencyLogo from '../DoubleLogo'
import { Lock } from 'react-feather'
import { ThemedText } from '../../theme'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useTheme from '../../hooks/useTheme'
import { Aligner, Container, CurrencySelect, FiatRow, FixedContainer, InputPanel, InputRow, Price, StyledBalanceMax, StyledDropDown, StyledNumericalInput, StyledTokenName } from './styledCurrencyInput'


export default function CurrencyInputPanel({
  value,
  onUserInput,
  trade,
  label,
  price,
  balances,
  tokens,
  outputNetwork,
  availableChains,
  onMax,
  showMaxButton,
  onCurrencySelect,
  currency,
  otherCurrency,
  id,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  loading = false,
  ...rest
}: any) {
  const [modalOpen, setModalOpen] = useState(false)
  const { account, chainId } = useActiveWeb3React()
  const theme = useTheme()
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  let selectedCurrencyBalance: any

  const getBalance = (
    currentBalances: { [ChainKey: string]: Array<TokenAmount> } | undefined,
    chainKey: ChainKey,
    tokenId: string
  ) => {
    if (!currentBalances || !currentBalances[chainKey]) {
      return 0
    }
    const tokenBalance =
      currentBalances && currentBalances[chainKey]?.find((tokenAmount) => tokenAmount.address === tokenId)

    return Number(tokenBalance?.amount)>0 ? Number(tokenBalance?.amount).toFixed(5) : 0
  }
  const excPrice = price?.result?.fromAmountUSD
  const outExePrice = price?.result?.toAmountUSD
  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <ThemedText.Label fontSize="12px" textAlign="center" padding="0 12px">
              <span>The market price is outside your specified price range. Single-asset deposit only.</span>
            </ThemedText.Label>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput}>
        {!hideInput && !hideBalance && currency && (
          <FiatRow>
            <RowBetween>
              <LoadingOpacityContainer $loading={loading}>
                <RowFixed style={{ height: '17px' }} top={'1rem'}>
                  <ThemedText.Body color={theme.text3} fontWeight={600} fontSize={16} style={{ display: 'inline' }}>
                    {label}
                  </ThemedText.Body>
                </RowFixed>
              </LoadingOpacityContainer>
              {account ? (
                <RowFixed style={{ height: '17px' }} top={'1rem'}>
                  <ThemedText.Body color={theme.text3} fontWeight={400} fontSize={15} style={{ display: 'inline' }}>
                    {!hideBalance ? (
                      renderBalance ? (
                        renderBalance(selectedCurrencyBalance)
                      ) : (
                        <span>
                          Balance: {getBalance(balances, CHAIN_IDS_TO_NAMES[Number(chainId)], currency.address)}
                        </span>
                      )
                    ) : null}
                  </ThemedText.Body>
                </RowFixed>
              ) : (
                <span />
              )}
            </RowBetween>
          </FiatRow>
        )}
        {!outputNetwork && excPrice ? <Price>{excPrice !== '0.00' ? `$${excPrice}` : '---'}</Price> : null}
        {outputNetwork && outExePrice ? <Price>{outExePrice !== '0.00' ? `$${outExePrice}` : '---'}</Price> : null}
        <div
          style={
            outputNetwork && !currency
              ? { marginTop: '30px' }
              : !currency
              ? { marginTop: '30px' }
              : { marginTop: '-20px' }
          }
        >
          <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={!onCurrencySelect}>
            {!hideInput && (
              <StyledNumericalInput
                className="token-amount-input"
                value={!trade?.result ? value : formatDecimals(trade?.result?.toToken, trade?.result?.toAmount)}
                onUserInput={onUserInput}
                $loading={loading}
              />
            )}
            {showMaxButton &&
            currency &&
            getBalance(balances, CHAIN_IDS_TO_NAMES[Number(chainId)], currency.address) > 0 ? (
              <StyledBalanceMax onClick={onMax}>
                <span>MAX</span>
              </StyledBalanceMax>
            ) : null}
            {onCurrencySelect && (
              <CurrencySelect
                visible={true}
                selected={!!currency}
                hideInput={hideInput}
                className="open-currency-select-button"
                onClick={() => {
                  setModalOpen(true)
                }}
              >
                <Aligner>
                  <RowFixed>
                    {pair ? (
                      <span style={{ marginRight: '0.5rem' }}>
                        <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                      </span>
                    ) : currency ? (
                      <img
                        style={{ marginRight: '0.5rem', width: '28px', borderRadius: '50%' }}
                        src={currency.logoURI}
                      />
                    ) : null}
                    {pair ? (
                      <StyledTokenName className="pair-name-container">
                        {pair?.token0.symbol}:{pair?.token1.symbol}
                      </StyledTokenName>
                    ) : (
                      <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                        {(currency && currency.symbol && currency.symbol.length > 20
                          ? currency.symbol.slice(0, 4) +
                            '...' +
                            currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                          : currency?.symbol) || <span>Selecciona un token</span>}
                      </StyledTokenName>
                    )}
                  </RowFixed>
                  <StyledDropDown selected={!!currency} />
                </Aligner>
              </CurrencySelect>
            )}
          </InputRow>
        </div>
      </Container>
      {onCurrencySelect && (
        <CurrencySearchModal
          balances={balances}
          tokens={tokens}
          availableChains={availableChains}
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          disableNonToken={disableNonToken}
        />
      )}
    </InputPanel>
  )
}
