import {  AlertTriangle, ArrowUpCircle, CheckCircle } from 'react-feather'
import { AutoColumn, ColumnCenter } from '../Column'
import { ButtonLight, ButtonPrimary } from '../Button'
import { CloseIcon, CustomLightSpinner } from '../../theme'
import { ReactNode, useContext } from 'react'
import { RowBetween, RowFixed } from '../Row'
import styled, { ThemeContext } from 'styled-components/macro'

import Circle from '../../assets/images/blue-loader.svg'
import { Currency } from '@uniswap/sdk-core'
import MetaMaskLogo from '../../assets/images/metamask.png'
import Modal from '../Modal'
import { Text } from 'rebass'
import { formatDecimals } from 'utils/formatCurrencyAmount'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'
import { useDarkModeManager } from 'state/user/hooks'
import { AdvancedSwapDetails } from 'components/swap/AdvancedSwapDetails'

const Wrapper = styled.div<{ darkMode?: boolean }>`
  width: 100%;
  background: ${({ theme, darkMode }) => (darkMode ? 'transparent' : theme.bg0)};
`
const Footer = styled.div<{ darkMode?: boolean }>`
  padding: 1rem;
  background: ${({ theme, darkMode }) => (!darkMode ? '#F5F7F9' : theme.bg1)};
`

const Section = styled(AutoColumn)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '0' : '0')};
`

const BottomSection = styled(Section)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '20px 0' : '32px 0;')};
`
const ConfirmText = styled.p`
  padding: 0.5rem 0.5rem;
  width: 100%;
  margin: 0px;
  font-weight: 500;
  font-size: 20px;
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`

function ConfirmationPendingContent({
  onDismiss,
  pendingText,
  inline,
}: {
  onDismiss: () => void
  pendingText: ReactNode
  inline?: boolean // not in modal
}) {
  return (
    <Wrapper>
      <AutoColumn gap="md">
        {!inline && (
          <RowBetween>
            <div />
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
        )}
        <ConfirmedIcon inline={inline}>
          <CustomLightSpinner src={Circle} alt="loader" size={inline ? '40px' : '90px'} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={500} fontSize={20} textAlign="center">
            <span>Waiting For Confirmation</span>
          </Text>
          <Text fontWeight={400} fontSize={16} textAlign="center">
            {pendingText}
          </Text>
          <Text fontWeight={500} fontSize={14} color="#565A69" textAlign="center" marginBottom="12px">
            <span>Confirm this transaction in your wallet</span>
          </Text>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  )
}
function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  inline,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: number
  currencyToAdd?: Currency | undefined
  inline?: boolean // not in modal
}) {
  const theme = useContext(ThemeContext)

  const { library } = useActiveWeb3React()

  const { addToken, success } = useAddTokenToMetamask(currencyToAdd)
  const [darkMode] = useDarkModeManager()

  return (
    <Wrapper darkMode={darkMode} style={{ padding: '1rem' }}>
      <Section inline={inline}>
        {!inline && (
          <RowBetween>
            <div />
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
        )}
        <ConfirmedIcon inline={inline}>
          <ArrowUpCircle strokeWidth={0.5} size={inline ? '40px' : '90px'} color={theme.primary1} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={500} fontSize={20} textAlign="center">
            <span>Transaction Submitted</span>
          </Text>
          {chainId && hash && (
            <a rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'none' }} href={hash}>
              <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                <span>Ver en Explorador</span>
              </Text>
            </a>
          )}
          {currencyToAdd && library?.provider?.isMetaMask && (
            <ButtonLight mt="12px" padding="6px 12px" width="fit-content" onClick={addToken}>
              {!success ? (
                <RowFixed>
                  <span>
                    Add {currencyToAdd.symbol} to Metamask <StyledLogo src={MetaMaskLogo} />
                  </span>
                </RowFixed>
              ) : (
                <RowFixed>
                  <span>Added {currencyToAdd.symbol} </span>
                  <CheckCircle size={'16px'} stroke={theme.green1} style={{ marginLeft: '6px' }} />
                </RowFixed>
              )}
            </ButtonLight>
          )}
          <ButtonPrimary onClick={onDismiss} style={{ margin: '20px 0 0 0' }}>
            <Text fontWeight={500} fontSize={20}>
              {inline ? <span>Return</span> : <span>Close</span>}
            </Text>
          </ButtonPrimary>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  swap,
  message,
  bottomContent,
  isSwapping,
  onDismiss,
  topContent,
  trade,
}: any) {
  const [darkMode] = useDarkModeManager()

  return (
    <Wrapper>
      {!isSwapping ? (
        <>
          <Section style={{ padding: '1rem' }}>
            <RowBetween style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ConfirmText>Confirmar Swap</ConfirmText>
              <CloseIcon onClick={onDismiss} />
            </RowBetween>

            {topContent()}
          </Section>
          <Footer darkMode={darkMode}>
            <AdvancedSwapDetails isSwap={swap} trade={trade?.result} />
            {bottomContent && <BottomSection gap="12px">{bottomContent()}</BottomSection>}
          </Footer>
        </>
      ) : (
        <div style={{ padding: '2rem' }}>
          <ConfirmedIcon>
            <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
          </ConfirmedIcon>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={500} fontSize={20} textAlign="center">
              <span>{message}</span>
            </Text>
            <Text fontWeight={400} fontSize={16} textAlign="center">
              <span>
                Swapping{' '}
                <b>
                  {formatDecimals(trade?.result?.fromToken, trade?.result?.fromAmount)}{' '}
                  {trade?.result?.fromToken.symbol}
                </b>{' '}
                for{' '}
                <b>
                  {formatDecimals(trade?.result?.toToken, trade?.result?.toAmount)} {trade?.result?.toToken?.symbol}
                </b>
              </span>
            </Text>

            <Text fontWeight={500} fontSize={14} color="#565A69" textAlign="center" marginBottom="12px">
              <span>Confirm this transaction in your wallet</span>
            </Text>
          </AutoColumn>
        </div>
      )}
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: any; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)
  if (message?.code === 1012) {
    message.message = 'Transaction rejected'
  }
 
  return (
    <Wrapper style={{ padding: '1rem' }}>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            <span>Error</span>
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
          <Text
            fontWeight={500}
            fontSize={16}
            color={theme.red1}
            style={{ textAlign: 'center', width: '85%', wordBreak: 'break-word' }}
          >
            {message?.message}
          </Text>
        </AutoColumn>
      </Section>
      <BottomSection gap="12px">
        <ButtonPrimary onClick={onDismiss}>
          <span>Dismiss</span>
        </ButtonPrimary>
      </BottomSection>
    </Wrapper>
  )
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
}: any) {
  const { chainId } = useActiveWeb3React()
  const isL2 = false

  if (!chainId) return null

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {isL2 && (hash || attemptingTxn) ? (
        <span />
      ) : attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}
