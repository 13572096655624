import { ArrowDown } from 'react-feather'
import { BridgeArrowWrapper, BridgeWrapper } from '../../components/bridge/styleds'
import { ButtonGray, ButtonLight } from '../../components/Button'
import { CHAIN_IDS_TO_NAMES } from 'constants/chains'
import { Chain, ChainKey , TokenAmount, Token, ExecutionSettings, RoutesRequest, RoutesResponse, getChainById } from '../../types'

import { useCallback, useEffect, useState } from 'react'
import { isWalletConnectWallet } from '../../components/services/localStorage'
import { switchChain } from '../../components/services/metamask'
import { useAllTransactions, useTransactionAdder } from 'state/transactions/hooks'

import AppBody from '../AppBody'
import { AutoColumn } from '../../components/Column'
import BigNumber from 'bignumber.js'
import BridgeInputPanel from '../../components/BridgeInputPanel'
import { CHAIN_INFO } from 'constants/chainInfo'
import ConfirmBridgeModal from '../../components/bridge/ConfirmBridgeModal'
import LiFi from '../../components/LiFi'
import Loader from '../../components/Loader'

import moment from 'moment'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useWalletModalToggle } from 'state/application/hooks'
import { useWeb3React } from 'web3-react-core'
import { v4 as uuid } from 'uuid'
import { maintenanceMode } from 'pages/maintenance'
import { PriceDetails } from 'components/swap/SwapDetailsDropdown'

export interface AddEthereumChainParameter {
  chainId: string
  blockExplorerUrls: string[]
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  rpcUrls: string[]
}

export interface Chain2 {
  key: any
  name: string
  coin: any
  id: number
  mainnet: boolean
  logoURI?: string
  tokenlistUrl?: string
  faucetUrls?: string[]
  metamask: AddEthereumChainParameter
  multicallAddress?: string
}

interface TokenWithAmounts extends Token {
  amount?: BigNumber
  amountRendered?: string
}

interface TokenAmountList {
  [ChainKey: string]: Array<TokenWithAmounts>
}

export default function Bridge() {
  const { account, chainId } = useActiveWeb3React()

  const addTransaction = useTransactionAdder()
  const allTransactions = useAllTransactions()

  const [depositAmount, setDepositAmount] = useState<any>()
  const [hasError, setHasError] = useState<boolean>()
  const [error, setError] = useState<any>()
  const [idRroute, setId] = useState<string>()
  const [isAllowing, setIsAllowing] = useState<boolean>()
  const [showMessage, setShowMessage] = useState<string>('Waiting for approval')

  const [fromTokenAddress, setFromTokenAddress] = useState<any>()
  const [toTokenAddress, setToTokenAddress] = useState<any>()
  const [availableChains, setAvailableChains] = useState<Chain[]>([])
  const [toChain, setToChain] = useState<any>(56)

  const [tokens, setTokens] = useState<TokenAmountList>({})
  const [refreshBalances, setRefreshBalances] = useState<boolean>(true)
  const [balances, setBalances] = useState<any>()
  const [openConfirmModal, setOpenConfirmModal] = useState<any>()
  const [isSwapping, setIsSwapping] = useState<boolean>(false)

  const [successfullyswapped, setSuccessfyllySwapped] = useState<string>()
  const [refresh, setRefresh] = useState<boolean>()

  //routes
  const [finalRoute, setFinalRoute] = useState<any>()
  const [noRoutesAvailable, setNoRoutesAvailable] = useState<boolean>(false)
  const [routesLoading, setRoutesLoading] = useState<boolean>(false)
  const [routeCallResult, setRouteCallResult] = useState<any>()
  //options

  const web3 = useWeb3React()

  const updateBalances = useCallback(async () => {
    if (web3.account) {
      // one call per chain to show balances as soon as the request comes back
      Object.entries(tokens).forEach(([chainKey, tokenList]) => {
        LiFi.getTokenBalances(web3.account!, tokenList).then((portfolio: any) => {
          setBalances((balances: any) => {
            if (!balances) balances = {}
            return {
              ...balances,
              [chainKey]: portfolio,
            }
          })
        })
      })
    }
  }, [web3.account, tokens])

  const toggleWalletModal = useWalletModalToggle()

  useEffect(() => {
    if (refreshBalances && web3.account) {
      setRefreshBalances(false)
      updateBalances()
    }
  }, [refreshBalances, web3.account, updateBalances])

  useEffect(() => {
    setRefreshBalances(true)
  }, [allTransactions])

  const handleMax = useCallback(() => {
    const tokenBalance = getBalance(balances, CHAIN_IDS_TO_NAMES[Number(chainId)], fromTokenAddress.address)
    if (tokenBalance.c && tokenBalance.c[0]) {
      setDepositAmount(tokenBalance)
    }
  }, [balances, CHAIN_IDS_TO_NAMES, fromTokenAddress])

  useEffect(() => {
    if (!web3.account) {
      setBalances(undefined) // reset old balances
    }
  }, [web3.account])

  useEffect(() => {
    if (!account) {
      setToChain(56)
    }
  }, [])

  
  const handleInputSelect = useCallback((inputCurrency:string) => {
    setFinalRoute('')
    setFromTokenAddress(inputCurrency)
  }, [])

  useEffect(() => {
    const fromNativeCurrency = CHAIN_INFO[Number(chainId)]?.nativeCurrency
    const nativeCurrency = CHAIN_INFO[Number(toChain)]?.nativeCurrency
    const fromOtherCurrency = CHAIN_INFO[Number(chainId)]?.bridgeCurrency
    const otherCurrency = CHAIN_INFO[Number(toChain)]?.bridgeCurrency
    if (fromNativeCurrency?.symbol === fromTokenAddress?.symbol) {
      setToTokenAddress(nativeCurrency)
    } else {
      const found = fromOtherCurrency?.find((cur: Token) => cur.symbol === fromTokenAddress?.symbol)
      const currency = otherCurrency?.find((cur: Token) => cur.symbol === found?.symbol)
      setToTokenAddress(currency)
    }
  }, [toChain, fromTokenAddress])

  const handleOutputNetworkSelect = useCallback(
    (outputNetwork:number) => {
      if (chainId === outputNetwork) {
        setToChain(CHAIN_INFO[Number(chainId)]?.to)
        setFinalRoute('')
      } else {
        setToChain(outputNetwork)
        setFinalRoute('')
      }
    },
    [chainId, toChain]
  )

  useEffect(() => {
    if (chainId === toChain) {
      setToChain(CHAIN_INFO[Number(chainId)]?.to)
    }
    setFromTokenAddress(CHAIN_INFO[Number(chainId)]?.bridgeCurrency[0])
    setDepositAmount('')
  }, [chainId])

  useEffect(() => {
    setRouteCallResult(null)
  }, [toChain, depositAmount])

  useEffect(() => {
    if (!depositAmount) {
      setFinalRoute('')
      setRoutesLoading(false)
    }
  }, [depositAmount])

  const findToken = useCallback(
    (chainKey: ChainKey, tokenId: string) => {
      const token = tokens && tokens[chainKey].find((token) => token.address === tokenId)
      return token
    },
    [tokens]
  )
  const status = localStorage.getItem('status')

  const slippage: string|null = localStorage.getItem('bSlipagge')

  const getRoutes = async () => {

    setNoRoutesAvailable(false)
    const isGreater = new BigNumber(depositAmount)
    if (isGreater.gt(0) && chainId && fromTokenAddress && toChain && toTokenAddress) {
      setRoutesLoading(true)
      if (tokens.eth) {
        const fromToken: Token|undefined = findToken(CHAIN_IDS_TO_NAMES[Number(chainId)], fromTokenAddress.address)
        const toToken: Token|undefined = findToken(CHAIN_IDS_TO_NAMES[Number(toChain)], toTokenAddress.address)
        if (fromToken && toToken) {
          const request:any = {
            fromChainId: fromToken.chainId,
            fromAmount: new BigNumber(depositAmount).shiftedBy(fromToken.decimals).toFixed(0),
            fromTokenAddress: fromTokenAddress.address,
            toChainId: toToken.chainId,
            toTokenAddress: toTokenAddress.address,
            fromAddress: account || undefined,
            toAddress: account || undefined,
            options: {
              order: status||'RECOMMENDED' ,
              slippage: Number(slippage) / 100,
              bridges: {
                allow: ['cbridge', 'connext', 'hop', 'hyphen', 'multichain', 'stargate', 'across'],
              },
            },
          }
          const id = uuid()
          try {
            setId(id)
            const result: RoutesResponse = await LiFi.getRoutes(request)
            if (!result.routes.length) {
              setRouteCallResult({ id })
            }
            const posibleArray = []

            const array = []
            for (let index = 0; index < result.routes.length; index++) {
              if (result.routes[index].steps.length > 1) {
              } else {
                const step:any = result.routes[index].steps[0]
                if (
                  result.routes[index].fromToken.address !== CHAIN_INFO[Number(chainId)]?.nativeCurrency.address &&
                  step.includedSteps.length > 2
                ) {
                } else {
                  if (status === 'RECOMMENDED') {
                    const firstStep = result.routes[index].steps[0].estimate
                    if ((Number(firstStep.fromAmountUSD) * 100) / Number(firstStep.toAmountUSD) >= 99.5) {
                      array.push({ result: result.routes[index], id, date: new Date() })
                    } else {
                      if (posibleArray.length) {
                        posibleArray.push({ result: result.routes[index], id, date: new Date() })
                      }
                    }
                  } else {
                    array.push({ result: result.routes[index], id, date: new Date() })
                  }
                }
              }
            }
            if (array.length || posibleArray.length) {
              setRouteCallResult(array[0] || posibleArray[0])
            } else {
              setRouteCallResult({ id })
            }
          } catch {
            if (id === idRroute || !idRroute) {
              setNoRoutesAvailable(true)
              setRoutesLoading(false)
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    const load = async () => {
      const possibilities = await LiFi.getPossibilities()

      if (!possibilities.chains || !possibilities.bridges || !possibilities.exchanges || !possibilities.tokens) {
        // eslint-disable-next-line
        console.warn('possibilities request did not contain required setup information')
        return
      }
      setAvailableChains(possibilities.chains)

      const newTokens: TokenAmountList = {}
      possibilities.tokens.forEach((token: TokenWithAmounts) => {
        const chain = getChainById(token.chainId)
        if (!newTokens[chain.key]) newTokens[chain.key] = []
        newTokens[chain.key].push(token)
      })

      setTokens((tokens) => {
        // which existing tokens are not included?
        Object.keys(tokens).forEach((chainKey) => {
          tokens[chainKey].forEach((token) => {
            if (!newTokens[chainKey]) newTokens[chainKey] = []
            if (!newTokens[chainKey].find((item) => item.address === token.address)) {
              newTokens[chainKey].push(token)

              // -> load token from API to get current version (e.g. if token was added via url)
              updateTokenData(token)
            }
          })
        })
        return newTokens
      })
      setRefreshBalances(true)
    }
    load()
  }, [])

  const updateBalance = async () => {
    if (web3.account && balances && balances[CHAIN_IDS_TO_NAMES[Number(chainId)]]?.length) {
      const foundIndexFrom =
        balances &&
        balances[CHAIN_IDS_TO_NAMES[Number(chainId)]].findIndex(
          (token: Token) => token?.address === fromTokenAddress?.address
        )
      LiFi.getTokenBalance(web3.account!, fromTokenAddress).then((portfolio: any) => {
        if (portfolio.amount != 0) {
          setBalances((balances: any) => {
            if (!balances) balances = {}
            if (balances[CHAIN_IDS_TO_NAMES[Number(chainId)]]) {
              balances[CHAIN_IDS_TO_NAMES[Number(chainId)]][foundIndexFrom] = portfolio
              return {
                ...balances,
              }
            }
          })
        }
      })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      updateBalance()
      setRefresh(!refresh)
    }, 15000)
  }, [refresh])

  useEffect(() => {
    if (routeCallResult) {
      if (!routeCallResult.result && routeCallResult.id === idRroute) {
        setNoRoutesAvailable(true)
        setRoutesLoading(false)
      }
      const { id } = routeCallResult
      if (id === idRroute && depositAmount) {
        setFinalRoute(routeCallResult)
      
        setRoutesLoading(false)
      }
    }
  }, [routeCallResult])

  useEffect(() => {
    getRoutes()
  }, [fromTokenAddress, toTokenAddress, depositAmount, slippage, status])

  const getBalance = (
    currentBalances: { [ChainKey: string]: Array<TokenAmount> } | undefined,
    chainKey: any,
    tokenId: string
  ) => {
    if (!currentBalances || !currentBalances[chainKey]) {
      return new BigNumber(0)
    }
    const tokenBalance = currentBalances[chainKey].find((tokenAmount) => tokenAmount.address === tokenId)
    return tokenBalance?.amount ? new BigNumber(tokenBalance?.amount) : new BigNumber(0)
  }

  const hasSufficientBalance = () => {
    if (!fromTokenAddress || !chainId) {
      return true
    }
    const requiredAmount = new BigNumber(depositAmount)
    const tokenBalance = getBalance(balances, CHAIN_IDS_TO_NAMES[Number(chainId)], fromTokenAddress.address)
    return requiredAmount?.lte(tokenBalance)
  }
  const updateTokenData = (token2: Token) => {
    LiFi.getToken(token2.chainId, token2.address).then((updatedToken: TokenWithAmounts) => {
      // sync optional properties
      updatedToken.logoURI = updatedToken.logoURI || token2.logoURI
      updatedToken.priceUSD = updatedToken.priceUSD || token2.priceUSD

      // update tokens
      setTokens((tokens: any) => {
        const chain = getChainById(updatedToken.chainId)
        if (!tokens[chain.key]) tokens[chain.key] = []
        const index = tokens[chain.key].findIndex((token: any) => token.address === updatedToken.address)
        if (index === -1) {
          tokens[chain.key].push(updatedToken)
        } else {
          tokens[chain.key][index] = updatedToken
        }
        return tokens
      })
    })
  }

  const updateCallback = (updatedRoute: any) => {
    if (
      updatedRoute &&
      updatedRoute?.steps[0].execution.process[0]?.type === 'TOKEN_ALLOWANCE' &&
      updatedRoute &&
      updatedRoute?.steps[0].execution.process[0]?.status === 'DONE'
    ) {
      setShowMessage('Waiting for confirmation')
    }
    if (CHAIN_INFO[Number(chainId)]?.nativeCurrency.address === fromTokenAddress.address) {
      setShowMessage('Waiting for confirmation')
    }
    if (updatedRoute && updatedRoute?.steps[0]) {
      if (
        updatedRoute?.steps[0].execution.process[0]?.type === 'TOKEN_ALLOWANCE' &&
        updatedRoute?.steps[0].execution.process[0]?.status !== 'DONE'
      )
        return
      if (
        updatedRoute?.steps[0].execution.process[0]?.status === 'PENDING' ||
        updatedRoute?.steps[0].execution.process[1]?.status === 'PENDING' ||
        updatedRoute?.steps[0].execution.process[2]?.status === 'PENDING'
      ) {
        addTransaction({
          type: 15,
          id: updatedRoute.id,
          txLink: updatedRoute?.steps[0].execution.process[0].txLink,
          excution: updatedRoute?.steps[0].execution.process,
          tool: updatedRoute?.steps[0].tool,
          fromToken: updatedRoute.fromToken,
          toToken: updatedRoute.toToken,
          fromAmount: updatedRoute.fromAmount,
          toAmount: updatedRoute.toAmount,
          status: 'pending',
        })
        for (let index = 0; index < updatedRoute?.steps[0].execution.process.length; index++) {
          if (updatedRoute?.steps[0].execution.process[index]?.type !== 'TOKEN_ALLOWANCE') {
            setSuccessfyllySwapped(updatedRoute?.steps[0].execution.process[index]?.txLink)
            setIsSwapping(false)
            break
          }
        }
      }
    }
  }

  const switchChainHook = async (requiredChainId: number) => {
    if (!web3.account || !web3.library) return

    if (isWalletConnectWallet(web3.account)) {
      let promiseResolver
      const signerAwaiter = new Promise<void>((resolve) => (promiseResolver = resolve))

      await signerAwaiter
      return web3.library.getSigner()
    }

    if ((await web3.library.getSigner().getChainId()) !== requiredChainId) {
      // Fallback is Metamask
      const switched = await switchChain(requiredChainId)
      if (!switched) {
        throw new Error('Chain was not switched')
      }
    }
    return web3.library.getSigner()
  }


  const startCrossChainSwap = async () => {
    if (!web3.account || !web3.library) return
    const signer = web3.library.getSigner()
    const executionSettings: ExecutionSettings = {
      updateCallback,
      switchChainHook,
      infiniteApproval: true,
    }
    setIsSwapping(true)
    const pending = Number(localStorage.getItem('pending'))
    try {
      const tx = await LiFi.executeRoute(signer, finalRoute.result, { ...executionSettings })
    } catch (e) {
      // eslint-disable-next-line no-console
      setHasError(true)
      setError(e)
      console.warn('Execution failed!', finalRoute)
      localStorage.setItem('pending', JSON.stringify(pending ? pending - 1 : 0)) // eslint-disable-next-line no-console
      console.error(e)
      setIsSwapping(false)
      return
    }
    setIsSwapping(false)
    updateBalances()
  }

  return (
    <>
      <AppBody>
        <BridgeWrapper id="bridge-page">
          <ConfirmBridgeModal
            outdatedRoutes={moment().subtract(1, 'minute').isAfter(moment(finalRoute?.date))}
            isOpen={openConfirmModal}
            close={() => {
              if (successfullyswapped) {
                setHasError(false)
                setError(false)
                setSuccessfyllySwapped('')
                setFinalRoute('')
                setDepositAmount('')
                setShowMessage('Waiting for approval')
              }
              if (!isSwapping) {
                setShowMessage('Waiting for approval')
                setHasError(false)
                setError(false)
                setOpenConfirmModal(false)
                setSuccessfyllySwapped('')
              }
            }}
            routesLoading={routesLoading}
            message={showMessage}
            isSwapping={isSwapping}
            successfullyswapped={successfullyswapped}
            trade={finalRoute}
            onAcceptChanges={() => getRoutes()}
            hasError={hasError}
            error={error}
            allowedSlippage={''}
            onConfirm={startCrossChainSwap}
            onDismiss={() => {
              if (successfullyswapped) {
                setHasError(false)
                setError(false)
                setSuccessfyllySwapped('')
                setFinalRoute('')
                setDepositAmount('')
              }
              if (!isSwapping) {
                setHasError(false)
                setError(false)
                setOpenConfirmModal(false)
                setSuccessfyllySwapped('')
              }
            }}
          />

          <AutoColumn gap={'sm'}>
            <div style={{ display: 'relative' }}>
              <BridgeInputPanel
                value={depositAmount}
                showMaxButton={true}
                hideBalance={false}
                currency={fromTokenAddress}
                outputNetwork={false}
                price={finalRoute}
                balances={balances}
                tokens={tokens}
                availableChains={availableChains}
                onUserInput={setDepositAmount}
                network={toChain}
                onMax={handleMax}
                onNetworkSelect={handleOutputNetworkSelect}
                onCurrencySelect={handleInputSelect}
                otherCurrency={''}
                showCommonBases={true}
                id="bridge-currency-input"
                loading={''}
              />
              <BridgeArrowWrapper style={{ marginBottom: '20px' }} clickable>
                <ArrowDown size="16" />
              </BridgeArrowWrapper>
              <BridgeInputPanel
                value={''}
                outputNetwork={true}
                balances={balances}
                tokens={tokens}
                price={finalRoute}
                availableChains={availableChains}
                trade={finalRoute}
                showMaxButton={false}
                hideBalance={false}
                network={toChain}
                currency={toTokenAddress}
                onNetworkSelect={handleOutputNetworkSelect}
                showCommonBases={true}
                id="bridge-currency-output"
                loading={routesLoading}
              />
            </div>
            <PriceDetails trade={finalRoute?.result} loading={routesLoading} />
            <div>
              {maintenanceMode() ? (
                <ButtonGray>
                  <span>Actualmente en mantenimiento</span>
                </ButtonGray>
              ) : routesLoading ? null : !account && noRoutesAvailable ? (
                <ButtonGray>
                  <span>No hay rutas posibles</span>
                </ButtonGray>
              ) : !account && !noRoutesAvailable ? (
                <ButtonLight onClick={toggleWalletModal}>
                  <span>Conecta tu billetera</span>
                </ButtonLight>
              ) : isAllowing && !routesLoading ? (
                <ButtonLight>
                  <Loader />
                </ButtonLight>
              ) : !toChain ? (
                <ButtonGray>
                  <span>Selecciona una cadena destino</span>
                </ButtonGray>
              ) : !depositAmount ? (
                <ButtonGray>
                  <span>Ingresa una cantidad</span>
                </ButtonGray>
              ) : noRoutesAvailable ? (
                <ButtonGray>
                  <span>No hay rutas posibles</span>
                </ButtonGray>
              ) : !hasSufficientBalance() && finalRoute ? (
                <ButtonGray>
                  <span>Fondos insuficientes</span>
                </ButtonGray>
              ) : hasSufficientBalance() && !routesLoading && finalRoute ? (
                <ButtonLight onClick={() => setOpenConfirmModal(true)}>
                  <span>Swap</span>
                </ButtonLight>
              ) : !routesLoading && finalRoute ? (
                <ButtonGray onClick={() => setOpenConfirmModal(true)}>
                  <span>Swap</span>
                </ButtonGray>
              ) : null}
            </div>
          </AutoColumn>
        </BridgeWrapper>
      </AppBody>
    </>
  )
}
