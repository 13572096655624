import MULTICALL_ABI from './abi.json'
import { SupportedChainId } from '../chains'

const MULTICALL_NETWORKS: any = {
  [SupportedChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [SupportedChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [SupportedChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [SupportedChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [SupportedChainId.GOERLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [SupportedChainId.BSC_MAINNET]: '0xe348b292e8eA5FAB54340656f3D374b259D658b8',
  [SupportedChainId.BSC_TESTNET]: '0xe348b292e8eA5FAB54340656f3D374b259D658b8',

  [SupportedChainId.FANTOM_OPERA_MAINNET]: '0x0118EF741097D0d3cc88e46233Da1e407d9ac139',
  [SupportedChainId.FANTOM_OPERA_TESTNET]: '',
  [SupportedChainId.AVALANCHE_MAINNET]: '0xbB8Ce0A08F8d41b7cd3F1Cd0D94d5adB4B58729E',
  // 0xa00FB557AA68d2e98A830642DBbFA534E8512E5f, 0x0FB54156B496b5a040b51A71817aED9e2927912E both should work
  [SupportedChainId.AVALANCHE_FUJI_TESTNET]: '',

  [SupportedChainId.POLYGON]: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
  [SupportedChainId.POLYGON_MUMBAI]: '0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
