import {
  ChainKey,
  TokenAmount,
} from '../types'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import {  ThemedText } from '../../theme'
import { ReactNode, useEffect, useState } from 'react'
import {  RowFixed } from '../Row'
import {  formatDecimals } from 'utils/formatCurrencyAmount'

import { AutoColumn } from 'components/Column'

import BridgeNetworkSelector from './BridgeNetworkSelector'
import { CHAIN_IDS_TO_NAMES } from 'constants/chains'
import { CHAIN_INFO } from 'constants/chainInfo'

import DoubleCurrencyLogo from '../DoubleLogo'

import { Lock } from 'react-feather'
import { Pair } from '@uniswap/v2-sdk'

import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useTheme from '../../hooks/useTheme'
import { Aligner, Container, CurrencySelect, FiatRow, FixedContainer, FlyoutMenu, HideForMobile, InputPanel, InputRow, NetworkRow, Row, StyledBalanceMax, StyledDropDown, StyledNumericalInput, StyledTokenName } from './styledBridgeInput'


export default function BridgeInputPanel({
  value,
  trade,
  setWithdrawAmount,
  tokens,
  onUserInput,
  availableChains,
  label,
  price,
  onMax,
  showMaxButton,
  balances,
  onCurrencySelect,
  onNetworkSelect,
  outputNetwork,
  currency,
  network,
  otherCurrency,
  id,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, 
  hideInput = false,
  locked = false,
  loading,
  ...rest
}: any) {
  const { account, chainId } = useActiveWeb3React()
  const [posibletokens, setPossibleTokens] = useState<any>()
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const toggle = () => setOpen(!open)

  useEffect(() => {
    setPossibleTokens(CHAIN_INFO[Number(chainId)])
  }, [CHAIN_INFO, chainId])

  let selectedCurrencyBalance: any

  const getBalance = (
    currentBalances: { [ChainKey: string]: Array<TokenAmount> } | undefined,
    chainKey: ChainKey,
    tokenId: string
  ) => {
    if (!currentBalances || !currentBalances[chainKey]) {
      return 0
    }
    const tokenBalance =
      currentBalances && currentBalances[chainKey]?.find((tokenAmount) => tokenAmount.address === tokenId)

    return Number(tokenBalance?.amount)>0 ? Number(tokenBalance?.amount).toFixed(5) : 0
  }

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <ThemedText.Label fontSize="12px" textAlign="center" padding="0 12px">
              <span>The market price is outside your specified price range. Single-asset deposit only.</span>
            </ThemedText.Label>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput}>
        {!hideInput && !hideBalance && (
          <NetworkRow>
            <RowFixed>
              <LoadingOpacityContainer $loading={false}>
                <RowFixed style={{ height: '17px', marginLeft: '-15px' }} top={'-0.6rem'}>
                  <ThemedText.Body
                    background-color={theme.bg1}
                    color={theme.text3}
                    fontWeight={600}
                    fontSize={16}
                    style={{ display: 'inline' }}
                  >
                    <HideForMobile>{label}</HideForMobile>
                  </ThemedText.Body>
                  <BridgeNetworkSelector
                    availableChains={availableChains}
                    isOutputNetwork={outputNetwork}
                    network={network}
                    onNetworkSelect={onNetworkSelect}
                  />
                </RowFixed>
              </LoadingOpacityContainer>
            </RowFixed>
          </NetworkRow>
        )}

        {!hideInput && !hideBalance && currency && (
          <FiatRow>
            {account ? (
              <RowFixed style={{ height: '17px', margin: '15px 0px', marginTop: '10px' }} top={'-2rem'}>
                <ThemedText.Body color={theme.text3} fontWeight={400} fontSize={15} style={{ display: 'inline' }}>
                  {!hideBalance && !outputNetwork && currency ? (
                    renderBalance ? (
                      renderBalance(selectedCurrencyBalance)
                    ) : (
                      <span>
                        Balance: {getBalance(balances, CHAIN_IDS_TO_NAMES[Number(chainId)], currency.address)}
                      </span>
                    )
                  ) : (
                    <div style={{ minHeight: '57px' }} />
                  )}
                </ThemedText.Body>
              </RowFixed>
            ) : (
              <span />
            )}
          </FiatRow>
        )}
        <InputRow
          style={
            !account
              ? { marginTop: '30px', paddingBottom: '0px' }
              : !outputNetwork
              ? { marginTop: '-8px', paddingBottom: '0px' }
              : !currency
              ? { marginTop: '65px' }
              : { marginTop: '-20px' }
          }
          selected={!onCurrencySelect}
        >
          {!hideInput && (
            <StyledNumericalInput
              className="token-amount-input"
              value={
                !trade?.result
                  ? value
                  : !loading
                  ? formatDecimals(trade.result?.toToken, trade?.result?.toAmount)
                  : null
              }
              onUserInput={onUserInput}
              $loading={loading}
            />
          )}
          {showMaxButton &&
          currency &&
          getBalance(balances, CHAIN_IDS_TO_NAMES[Number(chainId)], currency.address) > 0 ? (
            <StyledBalanceMax onClick={onMax}>
              <span>MAX</span>
            </StyledBalanceMax>
          ) : null}

          {outputNetwork && currency ? (
            <div style={!account ? { marginTop: '-10px', paddingBottom: '0px' } : {}}>
              <CurrencySelect
                visible={true}
                // visible={currency !== undefined}

                selected={!!currency}
                hideInput={hideInput}
                className="open-currency-select-button"
              >
                <img style={{ marginRight: '0.5rem', width: '28px', borderRadius: '50%' }} src={currency?.logoURI} />
                <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? currency.symbol.slice(0, 4) +
                      '...' +
                      currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                    : currency?.symbol) || <span></span>}
                </StyledTokenName>
              </CurrencySelect>
            </div>
          ) : null}

          {onCurrencySelect && (
            <CurrencySelect
              visible={true}
              // visible={currency !== undefined}
              selected={!!currency}
              hideInput={hideInput}
              className="open-currency-select-button"
              onClick={() => {
                toggle()
              }}
            >
              <Aligner>
                <RowFixed>
                  {pair ? (
                    <span style={{ marginRight: '0.5rem' }}>
                      <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                    </span>
                  ) : currency ? (
                    <img style={{ marginRight: '0.5rem', width: '28px', borderRadius: '50%' }} src={currency.logoURI} />
                  ) : null}
                  {pair ? (
                    <StyledTokenName className="pair-name-container">
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </StyledTokenName>
                  ) : (
                    <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                      {(currency && currency.symbol && currency.symbol.length > 20
                        ? currency.symbol.slice(0, 4) +
                          '...' +
                          currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                        : currency?.symbol) || ''}
                    </StyledTokenName>
                  )}
                </RowFixed>
                {currency ? <StyledDropDown selected={!!currency} /> : null}
              </Aligner>
            </CurrencySelect>
          )}
        </InputRow>
      </Container>
      {open && (
        <FlyoutMenu onMouseLeave={toggle} style={{ zIndex: 9 }}>
          {posibletokens?.bridgeCurrency.map((token: any) => {
            return (
              <Row
                onClick={() => {
                  onCurrencySelect(token)
                  toggle()
                }}
                key={token?.address}
              >
                <img style={{ width: 30, height: 30, marginRight: '10px', borderRadius: '50%' }} src={token.logoURI} />
                <p style={{ margin: '5px 0px' }} key={token?.name}>
                  {token?.name}
                </p>
              </Row>
            )
          })}
        </FlyoutMenu>
      )}
    </InputPanel>
  )
}
