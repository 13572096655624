import BigNumber from "bignumber.js"
import { CHAIN_IDS_TO_NAMES } from "constants/chains"
import { findDefaultToken, getChainById } from "types"

export const hasSufficientBalance = ({fromTokenAddress,chainId,balances,depositAmount,getBalance}:any) => {
    if (!fromTokenAddress || !chainId) {
      return true
    }
    const requiredAmount = new BigNumber(depositAmount)
    const tokenBalance = getBalance(balances, CHAIN_IDS_TO_NAMES[Number(chainId)], fromTokenAddress.address)
    return requiredAmount?.lte(tokenBalance)
  }

  
  export const hasSufficientGasBalanceOnStartChain = ({finalRoute , getBalance,balances}: any) => {
    if (!finalRoute.result) {
      return true
    }

    const fromChain = getChainById(finalRoute.result?.fromChainId)
    const token = findDefaultToken(fromChain.coin, fromChain.id)
    const balance = getBalance(balances, fromChain.key, token.address)
    const requiredAmount = finalRoute.result.steps
      ?.filter((step: any) => step.action.fromChainId === finalRoute.fromChainId)
      ?.map((step: any) => step.estimate.gasCosts && step.estimate.gasCosts.length && step.estimate.gasCosts[0].amount)
      ?.map((amount: any) => new BigNumber(amount || '0'))
      ?.reduce((a: any, b: any) => a.plus(b), new BigNumber(0))
      ?.shiftedBy(-18)
    return !balance.isZero() && balance.gte(requiredAmount)
  }
