import {  useCallback, useMemo } from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from '../TransactionConfirmationModal'

import SwapModalFooter from './SwapModalFooter'
import SwapModalHeader from './SwapModalHeader'

export default function ConfirmSwapModal({
  trade,
  outdatedRoutes,
  routesLoading,
  error,
  isSwapping,
  message,
  successfullyswapped,
  originalTrade,
  onAcceptChanges,
  allowedSlippage,
  onConfirm,
  onDismiss,
  recipient,
  swapErrorMessage,
  isOpen,
  hasError,
}: any) {
  const showAcceptChanges = useMemo(() => Boolean(trade && originalTrade), [originalTrade, trade])
  const modalHeader = useCallback(() => {
    return (
      <SwapModalHeader
        outdatedRoutes={outdatedRoutes}
        routesLoading={routesLoading}
        trade={trade}
        allowedSlippage={allowedSlippage}
        recipient={recipient}
        showAcceptChanges={showAcceptChanges}
        onAcceptChanges={onAcceptChanges}
      />
    )
  }, [allowedSlippage, onAcceptChanges, recipient, showAcceptChanges, message, trade, outdatedRoutes, routesLoading])

  const modalBottom = useCallback(() => {
    return trade ? (
      <SwapModalFooter
        onConfirm={onConfirm}
        outdatedRoutes={outdatedRoutes}
        trade={trade}
        disabledConfirm={showAcceptChanges}
        swapErrorMessage={swapErrorMessage}
      />
    ) : null
  }, [onConfirm, showAcceptChanges, swapErrorMessage, trade, outdatedRoutes])

  // text to show while loading
  const pendingText = (
    <span>
      Swapping {trade?.inputAmount?.toSignificant(6)} {trade?.inputAmount?.currency?.symbol} for{' '}
      {trade?.outputAmount?.toSignificant(6)} {trade?.outputAmount?.currency?.symbol}
    </span>
  )

  const confirmationContent = useCallback(
    () =>
      hasError && error !== 'Cannot add property doneAt, object is not extensible' ? (
        <TransactionErrorContent onDismiss={onDismiss} message={error} />
      ) : (
        <ConfirmationModalContent
          message={message}
          swap={true}
          trade={trade}
          title={<span>Confirmar Swap</span>}
          onDismiss={onDismiss}
          isSwapping={isSwapping}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, message, swapErrorMessage, isSwapping, trade, error, hasError]
  )
  return (
    <TransactionConfirmationModal
      swap={true}
      isOpen={isOpen}
      hash={successfullyswapped}
      onDismiss={onDismiss}
      content={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={trade?.fromToken?.address}
    />
  )
}
