import { Currency } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'

import useHttpLocations from 'hooks/useHttpLocations'
import { useMemo } from 'react'

type Network = 'ethereum' | 'binance' | 'avalanchec' | 'fantom' | 'harmony' | 'polygon'

function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    case SupportedChainId.MAINNET:
      return 'ethereum'
    case SupportedChainId.FANTOM_OPERA_TESTNET:
    case SupportedChainId.FANTOM_OPERA_MAINNET:
      return 'fantom'
    case SupportedChainId.AVALANCHE_FUJI_TESTNET:
    case SupportedChainId.AVALANCHE_MAINNET:
      return 'avalanchec'
    case SupportedChainId.BSC_TESTNET:
    case SupportedChainId.BSC_MAINNET:
      return 'binance'

    case SupportedChainId.POLYGON:
    case SupportedChainId.POLYGON_MUMBAI:
      return 'polygon'
    default:
      return 'ethereum'
  }
}



function getTokenLogoURI(address: string, chainId: SupportedChainId = SupportedChainId.MAINNET): string | void {
  const networkName = chainIdToNetworkName(chainId)
  const networksWithUrls = [
    SupportedChainId.MAINNET,
    SupportedChainId.FANTOM_OPERA_MAINNET,
    SupportedChainId.AVALANCHE_MAINNET,
    SupportedChainId.BSC_MAINNET,
  ]
  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${address}/logo.png`
  }
}

export default function useCurrencyLogoURIs(currency?: Currency | null): string[] {
  const locations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
       if (currency.isToken) {
        const logoURI = getTokenLogoURI(currency.address, currency.chainId)
        if (logoURI) {
          logoURIs.push(logoURI)
        }
      }
    }
    return logoURIs
  }, [currency, locations])
}
