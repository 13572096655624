import { SupportedChainId } from './chains'
import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { constructSameAddressMap } from '../utils/constructSameAddressMap'

type AddressMap = { [chainId: number]: string }

export const ZEROX_API_URLS: AddressMap = {
  [SupportedChainId.MAINNET]: 'https://api.0x.org/',
  [SupportedChainId.ROPSTEN]: 'https://ropsten.api.0x.org/',
  [SupportedChainId.KOVAN]: 'https://kovan.api.0x.org/',
  [SupportedChainId.RINKEBY]: 'https://rinkeby.api.0x.org/',
  [SupportedChainId.BSC_MAINNET]: 'https://bsc.api.0x.org/',
  [SupportedChainId.POLYGON]: 'https://polygon.api.0x.org/',
  [SupportedChainId.POLYGON_MUMBAI]: '',
  [SupportedChainId.AVALANCHE_MAINNET]: 'https://avalanche.api.0x.org/',
  [SupportedChainId.FANTOM_OPERA_MAINNET]: 'https://fantom.api.0x.org/',
}

export const ZEROX_EXCHANGE_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [SupportedChainId.ROPSTEN]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [SupportedChainId.KOVAN]: '0x4eacd0af335451709e1e7b570b8ea68edec8bc97',
  [SupportedChainId.RINKEBY]: '0x198805e9682fceec29413059b68550f92868c129',
  [SupportedChainId.BSC_MAINNET]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [SupportedChainId.POLYGON]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [SupportedChainId.POLYGON_MUMBAI]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [SupportedChainId.AVALANCHE_MAINNET]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  [SupportedChainId.FANTOM_OPERA_MAINNET]: '0xdef189deaef76e379df891899eb5a00a94cbc250',
}

export const CBRIDGE_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820',
  [SupportedChainId.BSC_MAINNET]: '0xdd90E5E87A2081Dcf0391920868eBc2FFB81a1aF',
  [SupportedChainId.POLYGON]: '0x88DCDC47D2f83a99CF0000FDF667A468bB958a78',
  [SupportedChainId.AVALANCHE_MAINNET]: '0xef3c714c9425a8F3697A9C969Dc1af30ba82e5d4',
  [SupportedChainId.FANTOM_OPERA_MAINNET]: '0x374B8a9f3eC5eB2D97ECA84Ea27aCa45aa1C57EF',
}

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')
export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
}
export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V2_FACTORY_ADDRESS)

export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')
export const V3_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0xE592427A0AEce92De3Edee1F18E0157C05861564', [
  SupportedChainId.POLYGON,
  SupportedChainId.POLYGON_MUMBAI,
])
export const SWAP_ROUTER_ADDRESSES: AddressMap = constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', [
  SupportedChainId.POLYGON,
  SupportedChainId.POLYGON_MUMBAI,
])

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V3_FACTORY_ADDRESS, [
  SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.POLYGON,
])
export const QUOTER_ADDRESSES: AddressMap = constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
  SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.POLYGON,
])
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = constructSameAddressMap(
  '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  [SupportedChainId.POLYGON_MUMBAI, SupportedChainId.POLYGON]
)
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
  SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.POLYGON,
])

export const TICK_LENS_ADDRESSES: AddressMap = {}
