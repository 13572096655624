import { CheckCircle, Triangle } from 'react-feather'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'

import { ExternalLink } from '../../theme'
import Loader from '../Loader'
import { RowFixed } from '../Row'
import { TransactionSummary } from './TransactionSummary'
import styled from 'styled-components/macro'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useAllTransactions } from '../../state/transactions/hooks'

const TransactionStatusText = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  :hover {
    text-decoration: underline;
  }
`

const TransactionState = styled(ExternalLink)<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.primary1};
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) => (pending ? theme.primary1 : success ? theme.green1 : theme.red1)};
`

export default function Transaction({ info }: { info: any }) {
  const { chainId } = useActiveWeb3React()
  const allTransactions = useAllTransactions()

  const tx = allTransactions?.[info]
  const info2 = tx?.info
  const pending = info.info.status==='pending'
  const success = info.info.status==='close'
  if (!chainId) return null
 let txLink
 if(info.info.txLink){
  txLink=info.info.txLink
 }else{
  info.info?.excution.forEach((tx:any) => {
    if(tx.type!=='TOKEN_ALLOWANCE'){
      txLink=info.info?.excution&&info.info?.excution[1].txLink
    }
  })
 }


  return (
    <div>
      <TransactionState
        href={txLink}
        pending={pending}
        success={success}
      >
        <RowFixed>
          <TransactionStatusText>
            <TransactionSummary info={info} /> ↗
          </TransactionStatusText>
        </RowFixed>
        <IconWrapper pending={pending} success={success}>
          {pending ? <Loader /> : success ? <CheckCircle size="16" /> : <Triangle size="16" />}
        </IconWrapper>
      </TransactionState>
    </div>
  )
}
