// eslint-disable-next-line no-restricted-imports

import { UnsupportedChainIdError, useWeb3React } from 'web3-react-core'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import styled, { css } from 'styled-components/macro'
import { useEffect, useMemo, useState } from 'react'

import { AbstractConnector } from 'web3-react-abstract-connector'
import { Activity } from 'react-feather'
import { ButtonSecondary } from '../Button'
import { Connector } from '@web3-react/types'
import { NetworkContextName } from '../../constants/misc'
import StatusIcon from '../Identicon/StatusIcon'

import WalletModal from '../WalletModal'
import { addPopup } from 'state/application/reducer'
import axios from 'axios'
import { darken } from 'polished'
import { finalizeTransaction } from 'state/transactions/actions'
import { shortenAddress } from '../../utils'
import { useDarkModeManager } from 'state/user/hooks'
import { useDispatch } from 'react-redux'
import useENSName from '../../hooks/useENSName'
import { useWalletModalToggle } from '../../state/application/hooks'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;

  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 1px;

  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;

  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background-color: ${({ theme }) => theme.primary4};
  border: none;

  color: ${({ theme }) => theme.primaryText1};
  font-weight: 500;

  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.05, theme.primary4)};
    color: ${({ theme }) => theme.primaryText1};
  }

  ${({ faded }) =>
    faded &&
    css`
      background-color: ${({ theme }) => theme.primary5};
      border: 1px solid ${({ theme }) => theme.primary5};
      color: ${({ theme }) => theme.primaryText1};

      :hover,
      :focus {
        border: 1px solid ${({ theme }) => darken(0.05, theme.primary4)};
        color: ${({ theme }) => darken(0.05, theme.primaryText1)};
      }
    `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean; darkMode?: boolean }>`
  background-color: ${({ pending, theme, darkMode }) => (pending ? theme.primary1 : darkMode ? theme.bg3 : theme.bg1)};
  border: 1px solid ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg1)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  font-weight: 500;

  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.05, theme.bg3)};

    :focus {
      border: 1px solid ${({ pending, theme }) => (pending ? darken(0.1, theme.primary1) : darken(0.1, theme.bg2))};
    }
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: any, b: any) {
  return b.addedTime - a.addedTime
}



function WrappedStatusIcon({ connector }: { connector: AbstractConnector | Connector }) {
  return (
    <IconWrapper size={16}>
      <StatusIcon connector={connector} />
    </IconWrapper>
  )
}

function Web3StatusInner() {
  const { account, connector, error } = useWeb3React()

  const { ENSName } = useENSName(account ?? undefined)
  const dispatch = useDispatch()
  const [chars, setChars] = useState<number>()

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const [darkMode] = useDarkModeManager()

  const pending = sortedRecentTransactions.filter((tx: any) => tx.info.status === 'pending')
  if (pending?.length) {
    pending.forEach(async (tx: any) => {
      if (tx) {
        const params = {
          bridge: tx.info.tool,
          toChain: tx.info.toToken?.chainId,
          fromChain: tx.info.fromToken?.chainId,
          txHash: tx.info.excution && tx.info.excution[tx.info.excution?.length - 1]?.txHash,
        }
        try {
          const result = await axios.get('https://li.quest/v1/status', {
            params,
          })
          if (result.data.status === 'DONE') {
            if (tx.info.toChain !== tx.info.fromChain) {
              dispatch(addPopup({ content: { type: 15, info: tx.info }, key: `failed-network-switch` }))
            } else {
              dispatch(addPopup({ content: { type: 1, info: tx.info }, key: `failed-network-switch` }))
            }
            dispatch(
              finalizeTransaction({
                chainId: tx.info.fromToken?.chainId,
                hash: tx.info.id,
                receipt: tx,
                error: false,
              })
            )
          }
          if (result.data.substatus === 'CHAIN_NOT_AVAILABLE') {
            dispatch(
              finalizeTransaction({
                chainId: tx.info.fromToken?.chainId,
                hash: tx.info.id,
                receipt: tx,
                error: true,
              })
            )
          }
        } catch (e) {
          dispatch(
            finalizeTransaction({
              chainId: tx.info.fromToken?.chainId,
              hash: tx.info.id,
              receipt: tx,
              error: true,
            })
          )
        }
      }
    })
  }

  const toggleWalletModal = useWalletModalToggle()
  useEffect(() => {
    if (window.screen.width < 800) {
      setChars(1)
    } else {
      setChars(4)
    }
  }, [window])
  if (account) {
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} darkMode={darkMode} pending={false}>
        <>
          <Text>{ENSName || shortenAddress(account, chars)}</Text>
        </>

        {connector && <WrappedStatusIcon connector={connector} />}
      </Web3StatusConnected>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{error instanceof UnsupportedChainIdError ? <span>Red no valida</span> : <span>Error</span>}</Text>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={toggleWalletModal} faded={!account}>
  
        <Text>
          <span>Connectar</span>
        </Text>
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const pending = sortedRecentTransactions.filter((tx: any) => tx.info.status === 'pending')
  const confirmed = sortedRecentTransactions.filter(
    (tx: any) => tx.info.status === 'close' || tx.info.status === 'error'
  )
  return (
    <>
      <Web3StatusInner />
      {(contextNetwork.active || active) && (
        <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
      )}
    </>
  )
}
