import { RowBetween, RowFixed } from '../Row'
import {  ThemedText } from '../../theme'
import styled, { ThemeContext } from 'styled-components/macro'
import { useContext } from 'react'

import { AutoColumn } from '../Column'
import Card from 'components/Card'

import { formatDecimals } from 'utils/formatCurrencyAmount'

const StyledCard = styled(Card)`
  padding: 0;
`


export function AdvancedSwapDetails({ trade, isSwap }: any) {
  const theme = useContext(ThemeContext)
  const aggregatedDuration = trade?.steps.reduce(
    (duration: any, step: any) => duration + step.estimate?.executionDuration || 1,
    0
  )
  const parseSecondsAsTime = (seconds: number): string => {
    if (isNaN(seconds) || seconds < 0) {
      return ' - '
    }
    const minutes = Math.floor(seconds / 60)
    if (minutes < 1) return `less than a minute`
    return `${minutes} minutes`
  }
  const parsedDuration = parseSecondsAsTime(aggregatedDuration)
  const bridgeSlippage: any = localStorage.getItem('bSlipagge') || 3

  let result: any
  if (trade?.toAmountMin !== trade?.toAmount) {
    result = formatDecimals(trade?.toToken, trade?.toAmountMin)
  } else {
    const minimum = formatDecimals(trade?.toToken, trade?.toAmountMin)
    result = minimum - minimum * (bridgeSlippage / 100)
  }
  return (
    <StyledCard style={isSwap ? {} : { marginBottom: '10px', padding: '10px' }}>
      <AutoColumn gap="8px">
        {!isSwap ? (
          <RowBetween>
            <RowFixed>
              <ThemedText.SubHeader color={theme.text3}>
                <span>Estimated duration</span>
              </ThemedText.SubHeader>
            </RowFixed>

            <ThemedText.Black textAlign="right" fontSize={14}>
              {parsedDuration}
            </ThemedText.Black>
          </RowBetween>
        ) : null}
        {!isSwap ? (
          <RowBetween>
            <RowFixed>
              <ThemedText.SubHeader color={theme.text3}>
                <span>Minimum received</span>
              </ThemedText.SubHeader>
            </RowFixed>
            <ThemedText.Black textAlign="right" fontSize={14}>
              <span>
                {trade?.toAmountMin !== trade?.toAmount ? result : Number(result?.toFixed(5))} {trade?.toToken.symbol}
              </span>
            </ThemedText.Black>
          </RowBetween>
        ) : null}
      </AutoColumn>
    </StyledCard>
  )
}
