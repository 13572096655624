import { CHAIN_IDS_TO_NAMES, SupportedChainId } from 'constants/chains'
import { ExternalLink, HideSmall } from 'theme'

import { ArrowUpRight } from 'react-feather'
import { AutoRow } from '../Row'
import { CHAIN_INFO } from 'constants/chainInfo'
import { ChainId } from '@uniswap/smart-order-router'
import styled from 'styled-components/macro'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useDarkModeManager } from 'state/user/hooks'

const L2Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`

export const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0 20px 20px 20px;
`

const BodyText = styled.div`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px;
  font-size: 14px;
`
const RootWrapper = styled.div`
  position: relative;
  margin-top: 16px;
`

const SHOULD_SHOW_ALERT = {
  [SupportedChainId.ARBITRUM_ONE]: false,
  [SupportedChainId.AVALANCHE_MAINNET]: false,
  [SupportedChainId.BSC_MAINNET]: false,

  [SupportedChainId.FANTOM_OPERA_MAINNET]: false,
  [SupportedChainId.GNOSIS]: false,

  [SupportedChainId.OPTIMISTIC_ETHEREUM]: false,
  [SupportedChainId.POLYGON]: false,
  [SupportedChainId.MAINNET]: false,
}

type NetworkAlertChains = keyof typeof SHOULD_SHOW_ALERT

const ContentWrapper = styled.div<{ chainId: NetworkAlertChains; darkMode: boolean; logoUrl: string }>`
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  width: 100%;

  :before {
    background-image: url(${({ logoUrl }) => logoUrl});
    background-repeat: no-repeat;
    background-size: 300px;
    content: '';
    height: 300px;
    opacity: 0.1;
    position: absolute;
    transform: rotate(25deg) translate(-90px, -40px);
    width: 300px;
    z-index: -1;
  }
`
const Header = styled.h2`
  font-weight: 600;
  font-size: 16px;
  margin: 0;
`

const LinkOutToBridge = styled(ExternalLink)`
  align-items: center;
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 6px 8px;
  margin-right: 12px;
  text-decoration: none !important;
  width: 100%;
`

const StyledArrowUpRight = styled(ArrowUpRight)`
  margin-left: 12px;
  width: 24px;
  height: 24px;
`

export function shouldShowAlert(chainId: number | undefined): chainId is NetworkAlertChains {
  return CHAIN_IDS_TO_NAMES[Number(chainId)]
}
export function NetworkAlert() {
  const { chainId } = useActiveWeb3React()
  const [darkMode] = useDarkModeManager()

  if (!shouldShowAlert(chainId)) {
    return null
  }

  const { label, logoUrl, bridge } = CHAIN_INFO[chainId] ? CHAIN_INFO[chainId] : SupportedChainId.MAINNET

  return bridge ? (
    <RootWrapper>
      <ContentWrapper chainId={chainId} darkMode={darkMode} logoUrl={logoUrl}>
        <LinkOutToBridge href={bridge}>
          <BodyText>
            <L2Icon src={logoUrl} />
            <AutoRow>
              <Header>
                <span>{label} is not supported by Swap yet</span>
              </Header>
              <HideSmall>
                <span>Deposit tokens to other networks with Bridge.</span>
              </HideSmall>
            </AutoRow>
          </BodyText>
          <StyledArrowUpRight />
        </LinkOutToBridge>
      </ContentWrapper>
    </RootWrapper>
  ) : null
}
