import React, { useMemo } from 'react'
import { Text, TextProps as TextPropsOriginal } from 'rebass'
import styled, {
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
} from 'styled-components/macro'

import { Colors } from './styled'
import { transparentize } from 'polished'
import { useIsDarkMode } from '../state/user/hooks'

export * from './components'

type TextProps = Omit<TextPropsOriginal, 'css'>

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

// Migrating to a standard z-index system https://getbootstrap.com/docs/5.0/layout/z-index/
// Please avoid using deprecated numbers
export enum Z_INDEX {
  deprecated_zero = 0,
  deprecated_content = 1,
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

function colors(darkMode: boolean): Colors {
  return {
    darkMode,
    // base
    white,
    black,

    // text
    text1: darkMode ? '#FFFFFF' : '#000000',
    text2: darkMode ? '#C3C5CB' : '#565A69',
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#C3C5CB',
    text5: darkMode ? '#2C2F36' : '#EDEEF2',

    text6: darkMode ? 'red' : '#565A69', //network selector menu text
    note: darkMode ? '#65BFF1' : '#2891F9', //botton "terms of use" label

    // backgrounds / greys
    // backgrounds / greys
    bg1: darkMode ? '#171127' : '#FFFFFF', //background for From/To swap inputs
    bg2: darkMode ? '#2C2F36' : '#F7F8FA',
    bg3: darkMode ? '#000000' : '#FFF',
    bg4: darkMode ? '#565A69' : '#CED0D9',
    bg5: darkMode ? '#6C7284' : '#edeef2',

    bg0: darkMode ? '#181818' : '#FFF',
    bg6: darkMode ? '#000000' : '#C8D4FE', // swap, bridge, yield acive border
    bg7: darkMode ? '#1F1515' : '#F2F2F2', //border for From/To inputs
    bg8: darkMode ? '#161616' : '#f5f5f5', //swap bridge yield active background
    bg9: darkMode ? '#232323' : '#F0F5F1', //network selector background
    bg10: darkMode ? 'rgba(0,0,0,0.4)' : 'rgba(255,255,255,0.4)', //background for dropdown with swap details
    bgDialog: darkMode ? '#272727' : '#F4F7F6', //background for dropdown with swap details
    bgNetwork: darkMode ? '#1E1E1E' : '#FAFAFA', //background for network selector on bridge

    maxButtonBorder: darkMode ? '#7a5fc8' : '#7a5fc8',
    maxButtonText: darkMode ? '#7a5fc8' : '#7a5fc8',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    modalBGWindow: darkMode ? '#272727' : '#F7F8F8',

    //primary colors
    gradientButton: darkMode
      ? 'linear-gradient(270deg, rgba(127, 142, 163, 0.85) 29.86%, #2F6E9B 100%);'
      : 'linear-gradient(270deg, rgba(68, 141, 244, 0.66) 29.86%, #AFECFF 100%);', //select a token button

    //primary colors
    primary1: darkMode ? '#7a5fc8' : '#7a5fc8',
    primary2: darkMode ? '#6550a3' : '#6550a3',
    primary3: darkMode ? '#433273' : '#433273',
    primary4: darkMode ? '#000000' : '#000000',
    primary5: darkMode ? '#c1abff' : '#e4dff4',

    // color text
    primaryText1: darkMode ? '#55428C' : '#55428C',

    // secondary colors
    secondary1: darkMode ? '#55428C' : '#55428C',
    secondary2: darkMode ? '#55428C' : '#55428C',
    secondary3: darkMode ? '#55428C' : '#FDEAF1',

    // other
    red1: '#FD4040',
    red2: '#F82D3A',
    red3: '#D60000',
    green1: '#27AE60',
    yellow1: '#FFE270',
    yellow2: '#F3841E',
    blue1: '#2172E5',

    // other
    yellow3: '#F3B71E',
    blue2: darkMode ? '#5199FF' : '#0068FC',
    error: darkMode ? '#FD4040' : '#DF1F38',
    success: darkMode ? '#27AE60' : '#007D35',
    warning: '#FF8F00',

    // dont wanna forget these blue yet
    blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',

    // Added:
    tokenButtonGradientStart: '#008c6b',
    tokenButtonGradientEnd: '#005224',
    customCardGradientStart: '#008c6b',
    customCardGradientEnd: '#00c09c',
  }
}

function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

/**
 * Preset styles of the Rebass Text component
 */
export const ThemedText = {
  Main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  Link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  Label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'text1'} {...props} />
  },
  Note(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'note'} {...props} />
  },
  Black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  White(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  Body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  LargeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  MediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  SubHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  Small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  Blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  Yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow3'} {...props} />
  },
  DarkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  Gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  Italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  Error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  },
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Inter var', sans-serif;
  }
}
html,
body {
  margin: 0;
  padding: 0;
}
 a {
   color: ${colors(false).blue1}; 
 }
* {
  box-sizing: border-box;
}
button {
  user-select: none;
}
html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
  
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1} !important;
}
body {
  margin: 0;
  padding: 0;
  max-width:98vw;
  min-height: 98vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;
  background-image: ${({ theme }) =>
    `radial-gradient(50% 50% at 50% 50%, ${transparentize(0.7, theme.primary1)} 0%, ${transparentize(
      1,
      theme.bg1
    )} 100%)`};
}
a {
 color: ${({ theme }) => theme.blue1}; 
}
`