import {  injected, walletconnect, walletlink } from '../../connectors'

import { AbstractConnector } from 'web3-react-abstract-connector'
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'
import { Connector } from '@web3-react/types'
import Identicon from '../Identicon'
import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'

export default function StatusIcon({ connector }: { connector: AbstractConnector | Connector }) {
  switch (connector) {
    case injected:
      return <Identicon />
    case walletconnect:
      return <img src={WalletConnectIcon} alt={'WalletConnect'} />
    case walletlink:
      return <img src={CoinbaseWalletIcon} alt={'Coinbase Wallet'} />
    default:
      return <Identicon />
  }
}
