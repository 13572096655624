import { Route, Switch } from 'react-router-dom'
import { Suspense } from 'react'

import Bridge from './Bridge'
import Discord from './Discord'
import Header from '../components/Header'
import Loader from 'components/Loader'
import Popups from '../components/Popups'
import Swap from './Swap'
import Web3ReactManager from '../components/Web3ReactManager'
import styled from 'styled-components/macro'
import DefaultRoute from './Default'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 102%;
  padding: 120px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4rem 8px 16px 8px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  return (
    <>
      <Web3ReactManager>
        <AppWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            {/*<Polling />*/}
            <Suspense fallback={<Loader />}>
                <Switch>
                  <Route  exact path="/swap" component={Swap} />
                  <Route exact path="/cross" component={Bridge} />
                  <Route exact path="/discord" component={Discord} />
                  <Route path="/" component={DefaultRoute} />
                </Switch>
            </Suspense>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </Web3ReactManager>
    </>
  )
}
