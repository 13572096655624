import { RowBetween, RowFixed } from '../Row'
import { ThemedText } from '../../theme'

import SettingsTab from '../Settings'
import styled from 'styled-components/macro'

const StyledSwapHeader = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.13rem;
  border-radius: 0.5rem;
  margin-left: 15px;
  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg1};
  }

  svg {
    margin-top: 2px;
    margin-left: -5px;
  }
`
const slippage = localStorage.getItem('slippage')
export default function SwapHeader() {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <RowFixed>
          <ThemedText.Black fontWeight={500} fontSize={16}>
          </ThemedText.Black>
        </RowFixed>
        <RowFixed>
          <SettingsTab placeholderSlippage={slippage} />
        </RowFixed>
      </RowBetween>
    </StyledSwapHeader>
  )
}
