/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
 CRONOS=25,
  POLYGON = 137,
  POLYGON_MUMBAI = 80001,
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  AVALANCHE_MAINNET = 43114,
  AVALANCHE_FUJI_TESTNET = 43113,
  FANTOM_OPERA_MAINNET = 250,
  FANTOM_OPERA_TESTNET = 4002,
  OPTIMISTIC_ETHEREUM=10,
  ARBITRUM_ONE=42161,
  GNOSIS=100,
  CELO= 42220
}

export enum SwapSupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,

  POLYGON = 137,
  POLYGON_MUMBAI = 80001,

  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  GNOSIS=100,
  AVALANCHE_MAINNET = 43114,
  AVALANCHE_FUJI_TESTNET = 43113,

  FANTOM_OPERA_MAINNET = 250,
  FANTOM_OPERA_TESTNET = 4002,
}

export const CHAIN_IDS_TO_NAMES:any = {
  [SupportedChainId.MAINNET]: 'eth',
  [SupportedChainId.ROPSTEN]: 'ropsten',
  [SupportedChainId.RINKEBY]: 'rinkeby',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.KOVAN]: 'kovan',
  [SupportedChainId.POLYGON]: 'pol',
  [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [SupportedChainId.BSC_MAINNET]: 'bsc',
  [SupportedChainId.BSC_TESTNET]: 'bsc_testnet',
  [SupportedChainId.AVALANCHE_MAINNET]: 'ava',
  [SupportedChainId.AVALANCHE_FUJI_TESTNET]: 'avalanche_fuji_testnet',
  [SupportedChainId.FANTOM_OPERA_MAINNET]: 'ftm',
  [SupportedChainId.FANTOM_OPERA_TESTNET]: 'fantom_opera_testnet',
  [SupportedChainId.ARBITRUM_ONE]: 'arb',
  [SupportedChainId.CELO]: 'cel',
  [SupportedChainId.GNOSIS]: 'dai',
  [SupportedChainId.OPTIMISTIC_ETHEREUM]: 'opt',
  [SupportedChainId.CRONOS]: 'cro',
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export const SWAP_SUPPORTED_CHAIN_IDS: SwapSupportedChainId[] = Object.values(SwapSupportedChainId).filter(
  (id) => typeof id === 'number'
) as SwapSupportedChainId[]

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [SupportedChainId.MAINNET, SupportedChainId.POLYGON]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,

  SupportedChainId.POLYGON,
  SupportedChainId.POLYGON_MUMBAI,

  SupportedChainId.BSC_MAINNET,
  SupportedChainId.BSC_TESTNET,

  SupportedChainId.AVALANCHE_MAINNET,
  SupportedChainId.AVALANCHE_FUJI_TESTNET,
  SupportedChainId.FANTOM_OPERA_MAINNET,
  SupportedChainId.FANTOM_OPERA_TESTNET,


] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]
