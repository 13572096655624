// eslint-disable-next-line no-restricted-imports

import { RowBetween, RowFixed } from '../Row'
import { Sliders, X } from 'react-feather'
import { Trans, t } from '@lingui/macro'
import styled, { ThemeContext } from 'styled-components/macro'
import { useClientSideRouter, useDarkModeManager, useExpertModeManager } from '../../state/user/hooks'
import { useContext, useRef, useState } from 'react'
import { useModalOpen, useToggleSettingsMenu } from '../../state/application/hooks'

import { ApplicationModal } from '../../state/application/reducer'
import { AutoColumn } from '../Column'
import { ButtonError } from '../Button'
import Modal from '../Modal'
import { Percent } from '@uniswap/sdk-core'
import QuestionHelper from '../QuestionHelper'
import ReactGA from 'react-ga'
import { Settings as SettingsIcon } from 'react-feather'
import { ReactComponent as Slippage } from 'assets/svg/slippage.svg'
import { Text } from 'rebass'
import { ThemedText } from '../../theme'
import Toggle from '../Toggle'
import TransactionSettings from '../TransactionSettings'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

const StyledMenuIcon = styled.svg`
  height: 20px;
  width: 20px;

  line {
    stroke: ${({ theme }) => theme.text2};
  }

  :hover {
    opacity: 0.7;
  }
`

const DisplaySlippage = styled.span`
  height: 20px;
  width: 20px;
  padding-left: 5px;
  color: ${({ theme }) => theme.text1};
`

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  margin-right: 2px;
  background-color: transparent;
  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }
`
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 20.125rem;
  background-color: ${({ theme }) => theme.bg3};
  border: 1px solid ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 18.125rem;
  `};

  user-select: none;
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 20px;
`
const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  outline: none;
  background: ${({ theme }) => theme.bg1};
  :hover {
    border: 1px solid ${({ theme }) => theme.bg4};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
`

const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
  padding: 5px 12px;
  background-color: ${({ active, theme }) => active && theme.primary1};
  color: ${({ active, theme }) => (active ? theme.white : theme.text1)};
`

export default function SettingsTab({ placeholderSlippage }: { placeholderSlippage: any }) {

  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleSettingsMenu()

  const theme = useContext(ThemeContext)

  const [expertMode, toggleExpertMode] = useExpertModeManager()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)
  const oldStatus = localStorage.getItem('status')
  const [status, setStatus] = useState(oldStatus || 'RECOMMENDED')
  useOnClickOutside(node, open ? toggle : undefined)
  const [darkMode] = useDarkModeManager()

  const handleChangeStatus = (e: any) => {
    localStorage.setItem('status', e.target.id)
    setStatus(e.target.id)
  }
  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper>
          <AutoColumn gap="lg">
            <RowBetween style={{ padding: '0 2rem' }}>
              <div />
              <Text fontWeight={500} fontSize={20}>
                <span>Are you sure?</span>
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </RowBetween>
            <Break />
            <AutoColumn gap="lg" style={{ padding: '0 2rem' }}>
              <Text fontWeight={500} fontSize={20}>
                <span>
                  Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result
                  in bad rates and lost funds.
                </span>
              </Text>
              <Text fontWeight={600} fontSize={20}>
                <span>ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.</span>
              </Text>
              <ButtonError
                error={true}
                padding={'12px'}
                onClick={() => {
                  const confirmWord = `confirm`
                  if (window.prompt(`Please type the word "${confirmWord}" to enable expert mode.`) === confirmWord) {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}
              >
                <Text fontSize={20} fontWeight={500} id="confirm-expert-mode">
                  <span>Turn On Expert Mode</span>
                </Text>
              </ButtonError>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
      <StyledMenuButton onClick={toggle} id="open-settings-dialog-button" aria-label={`Transaction Settings`}>
        <StyledMenuIcon color={darkMode ? 'white' : ''}>
          {/*<Slippage />*/}
          <SettingsIcon size={20} />
        </StyledMenuIcon>
        {/*<DisplaySlippage>{placeholderSlippage.toFixed(2) + '%'}</DisplaySlippage>*/}

        {expertMode ? (
          <EmojiWrapper>
            <span role="img" aria-label="wizard-icon">
              🧙
            </span>
          </EmojiWrapper>
        ) : null}
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          <AutoColumn gap="md" style={{ padding: '1rem' }}>
            <Text style={darkMode ? { color: 'white' } : {}} fontWeight={600} fontSize={14}>
              <span>Ajustes de transaccion</span>
            </Text>
            <TransactionSettings swap={true} placeholderSlippage={placeholderSlippage} />
            <TransactionSettings swap={false} placeholderSlippage={placeholderSlippage} />
            <div>
              <div style={{ display: 'flex', marginBottom: '10px' }}>
                <ThemedText.Black fontWeight={400} fontSize={14} color={theme.text2}>
                  <span>Prioridad de la ruta</span>
                </ThemedText.Black>
                <QuestionHelper
                  text={
                    <span>
                     La transaccion de tu ruta priorizara la opcion que tengas seleccionada. Recomendamos usar ‘Mejor’ en la mayoria de los casos
                    </span>
                  }
                />
              </div>
              <Option id="RECOMMENDED" onClick={(e) => handleChangeStatus(e)} active={status === 'RECOMMENDED'}>
                <span>Mejor</span>
              </Option>
              <Option id="CHEAPEST" onClick={(e) => handleChangeStatus(e)} active={status === 'CHEAPEST'}>
                <span>Barata</span>
              </Option>
              <Option id="FASTEST" onClick={(e) => handleChangeStatus(e)} active={status === 'FASTEST'}>
                <span>Rapida</span>
              </Option>
            </div>
          </AutoColumn>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
