import {  ChevronDown } from 'react-feather'
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from 'constants/chains'

import { useCallback, useEffect, useRef, useState } from 'react'


import { CHAIN_INFO } from 'constants/chainInfo'
import { MEDIA_WIDTHS } from 'theme'
import { ParsedQs } from 'qs'
import { addPopup } from 'state/application/reducer'
import { replaceURLParam } from 'utils/routes'
import styled from 'styled-components/macro'
import { switchToNetwork } from '../../utils/switchToNetwork'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useAppDispatch } from '../../state/hooks'
import { useHistory } from 'react-router-dom'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useParsedQueryString from 'hooks/useParsedQueryString'
import usePrevious from 'hooks/usePrevious'



const FlyoutMenu = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
  padding: 8px;
  position: absolute;
  left: 0;
  right: 0;
  @media screen and (max-width: 800px) {
    top: 45px;
  }
  top: 64px;
  min-width: 150px;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    top: 40px;
  }
`
const FlyoutRow = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active }) => (active ? 'transparent' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 1px 8px;
  text-align: left;
  width: 100%;
`

const Logo = styled.img`
  height: 22px;
  width: 22px;
  margin-right: 8px;
  border-radius: 12px;
`
const NetworkLabel = styled.div`
  flex: 1 1 auto;
`
const SelectorLabel = styled(NetworkLabel)`
  font-size: 16px;
  font-weight: 500;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    display: block;
    margin-right: 8px;
  }
`
const SelectorControls = styled.div<{ interactive: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.bg0};
  border: 2px solid ${({ theme }) => theme.bg0};
  border-radius: 16px;
  color: ${({ theme }) => theme.text1};
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'auto')};
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 8px;
  z-index: 500;
  min-width: 150px;
`
const SelectorLogo = styled(Logo)<{ interactive?: boolean }>`
  cursor: pointer;
  display: block;
  position: relative;
  width: 24px;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  border-radius: 12px;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    display: block;
    margin-right: 8px;
  }
  :hover,
  :focus {
    border: 1px solid ${({ theme }) => theme.primary4};
    border-radius: 12px;
  }
`
const SelectorWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.bg9};
  border-radius: 16px;
  background-color: ${({ theme }) => theme.bg2};
  width: 100%;
  max-width: 200px;
  position: relative;
  display: inline-block;

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    margin-left: 1rem;
    position: relative;
  }
`

const StyledChevronDown = styled(ChevronDown)`
  width: 16px;
`


function Row({
  name,
  logoUri,
  network,
  targetChain,
  onSelectChain,
}: {
  name: string
  logoUri: string | undefined
  network?: number | null
  targetChain: SupportedChainId
  onSelectChain: (targetChain: number) => void
}) {
  const active = network === targetChain

  const rowContent = (
    <FlyoutRow onClick={() => onSelectChain(targetChain)} active={active}>
      <Logo src={logoUri} />
      <NetworkLabel>{name}</NetworkLabel>
   
    </FlyoutRow>
  )


  return rowContent
}

const getParsedChainId = (parsedQs?: ParsedQs, chainToken?: string) => {
  const chain = parsedQs && chainToken ? parsedQs[chainToken] : ''
  if (!chain || typeof chain !== 'string') return { urlChain: undefined, urlChainId: undefined }

  return { urlChain: chain.toLowerCase(), urlChainId: getChainIdFromName(chain) }
}

const getChainIdFromName = (name: string) => {
  const entry = Object.entries(CHAIN_IDS_TO_NAMES).find(([_, n]) => n === name)
  const chainId = entry?.[0]
  return chainId ? parseInt(chainId) : undefined
}

const getChainNameFromId = (id: string | number) => {
  // casting here may not be right but fine to return undefined if it's not a supported chain ID
  return CHAIN_IDS_TO_NAMES[id as SupportedChainId] || ''
}




export default function BridgeNetworkSelector({
  isOutputNetwork,
  network,
  onNetworkSelect,
  availableChains,
}: {
  availableChains: any[]
  isOutputNetwork?: boolean
  network?: number | null
  onNetworkSelect?: (network: number) => void
}) {
  const { chainId: connectedChainId, library } = useActiveWeb3React()
  const chainId = isOutputNetwork ? network : connectedChainId
  const param = ''
  const parsedQs = useParsedQueryString()
  const { urlChain, urlChainId } = getParsedChainId(parsedQs, param)
  const prevChainId = usePrevious(chainId)
  const node = useRef<HTMLDivElement>()
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => setOpen(!open)

  useOnClickOutside(node, open ? toggle : undefined)

  const history = useHistory()

  const info = chainId ? CHAIN_INFO[chainId] : undefined

  const dispatch = useAppDispatch()

  const handleChainSwitch = useCallback(
    (targetChain: number, skipToggle?: boolean) => {
      if (isOutputNetwork && onNetworkSelect) {
        onNetworkSelect(targetChain)
        history.replace({
          search: replaceURLParam(history.location.search, 'toChain', getChainNameFromId(targetChain)),
        })
                if (!skipToggle) {
          toggle()
        }
        return
      }

      if (!library) return
      switchToNetwork({ library, chainId: targetChain })
        .then(() => {
          if (!skipToggle) {
            toggle()
          }
          history.replace({
            search: replaceURLParam(history.location.search, 'fromChain', getChainNameFromId(targetChain)),
          })
        })
        .catch((error) => {
          console.error('Failed to switch networks', error)

          // we want app network <-> chainId param to be in sync, so if user changes the network by changing the URL
          // but the request fails, revert the URL back to current chainId
          if (chainId) {
            history.replace({
              search: replaceURLParam(history.location.search, 'fromChain', getChainNameFromId(chainId)),
            })
          }

          if (!skipToggle) {
            toggle()
          }

          dispatch(addPopup({ content: { failedSwitchNetwork: targetChain }, key: `failed-network-switch` }))
        })
    },
    [dispatch, library, toggle, history, chainId, connectedChainId]
  )

  useEffect(() => {
    if (!chainId || !prevChainId) return

    // when network change originates from wallet or dropdown selector, just update URL
    if (chainId !== prevChainId) {
      history.replace({ search: replaceURLParam(history.location.search, param, getChainNameFromId(chainId)) })
      // otherwise assume network change originates from URL
    } else if (urlChainId && urlChainId !== chainId) {
      // handleChainSwitch(urlChainId, true)
      // not switching since top header network switcher does the job
    }
  }, [chainId, urlChainId, prevChainId, handleChainSwitch, history])

  // set chain parameter on initial load if not there
  useEffect(() => {
    if (chainId && !urlChainId) {
      history.replace({ search: replaceURLParam(history.location.search, param, getChainNameFromId(chainId)) })
    }
  }, [chainId, history, urlChainId, urlChain])

  if (isOutputNetwork && !onNetworkSelect) return null


  return (
    <SelectorWrapper ref={node as any}>
      <SelectorControls onClick={toggle} interactive>
        {info ? (
          <>
            <SelectorLogo onClick={toggle} interactive src={info?.logoUrl} />
            <SelectorLabel>{info?.label}</SelectorLabel>
          </>
        ) : (
          ''
        )}
        {info ? <StyledChevronDown /> : null}
      </SelectorControls>
      {open && (
        <FlyoutMenu onMouseLeave={toggle} style={{ zIndex: 9 }}>
      
          {availableChains.map((chain) => {
            let name
            if (chain.name === 'Arbitrum One') {
              name = 'Arbitrum'
            }
            if (chain.name === 'Optimistic Ethereum') {
              name = 'Optimism'
            }
            if (!CHAIN_IDS_TO_NAMES[chain.id]) {
              return null
            }

            return (
              <Row
                logoUri={chain.logoURI}
                name={name || chain.name}
                key={chain.key}
                network={chain.id}
                onSelectChain={handleChainSwitch}
                targetChain={chain.id}
              />
            )
          })}
        </FlyoutMenu>
      )}
    </SelectorWrapper>
  )
}
