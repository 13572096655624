import { RowBetween, RowFixed } from '../Row'
import styled, { ThemeContext } from 'styled-components/macro'
import { useContext, useState } from 'react'
import { useSetUserSlippageTolerance, useUserSlippageTolerance } from 'state/user/hooks'

import { AutoColumn } from '../Column'
import { Percent } from '@uniswap/sdk-core'
import QuestionHelper from '../QuestionHelper'
import { ThemedText } from '../../theme'
import { darken } from 'polished'


enum SlippageError {
  InvalidInput = 'InvalidInput',
}



const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.primary1};
  outline: none;
  background: ${({ theme }) => theme.bg1};
  :hover {
    border: 1px solid ${({ theme }) => theme.bg4};
  }
  :focus {
    outline: none
  }
`

const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
  background-color: ${({ active, theme }) => active && theme.primary1};
  color: ${({ active, theme }) => (active ? theme.white : theme.text1)};
`

const Input = styled.input`
  
  font-size: 16px;
  width: auto;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  :focus {
    outline: none
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: right;
  background-color: ${({ theme }) => theme.bg1}
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
  height: 2rem;
  position: relative;
  padding: 0 0.75rem;
  flex: 1;
  border: ${({ theme, active, warning }) =>
    active ? `1px solid ${warning ? theme.red1 : theme.primary1}` : warning && `1px solid ${theme.red1}`};
  :hover {
    border: ${({ theme, active, warning }) =>
      active && `1px solid ${warning ? darken(0.1, theme.red1) : darken(0.1, theme.primary1)}`};
  }

  input {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 2rem;
  }
`


export default function TransactionSettings({ placeholderSlippage, swap }: any) {
  const theme = useContext(ThemeContext)
  const userSlippageTolerance = useUserSlippageTolerance()
  const setUserSlippageTolerance = useSetUserSlippageTolerance()
  placeholderSlippage = Number(placeholderSlippage)

  const bridgeSlippage = localStorage.getItem('bSlipagge') || 3
  const swapSlippage = localStorage.getItem('sSlipagge') || 0.5

  const [slippageInput, setSlippageInput] = useState(swapSlippage)
  const [slippageInputBridge, setSlippageInputBridge] = useState(bridgeSlippage)
  const [slippageError, setSlippageError] = useState<SlippageError | false>(false)


  function parseSlippageInput(value: string) {
    // populate what the user typed and clear the error
    if (swap) {
      localStorage.setItem('sSlipagge', value)
      setSlippageInput(value)
    } else {
      localStorage.setItem('bSlipagge', value)
      setSlippageInputBridge(value)
    }
    setSlippageError(false)
    if (value.length === 0) {
      setUserSlippageTolerance('auto')
    } else {
      const parsed = Math.floor(Number.parseFloat(value) * 100)

      if (!Number.isInteger(parsed) || parsed < 0 || parsed > 5000) {
        setUserSlippageTolerance('auto')
        if (value !== '.') {
          setSlippageError(SlippageError.InvalidInput)
        }
      } else {
        setUserSlippageTolerance(new Percent(parsed, 10_000))
      }
    }
  }


  return (
    <AutoColumn gap="md">
      <AutoColumn gap="sm">
        <RowFixed>
          <ThemedText.Black fontWeight={400} fontSize={14} color={theme.text2}>
            <span>{swap ? 'Swap' : 'Cross'} Slippage tolereancia </span>
          </ThemedText.Black>
          <QuestionHelper
            text={
              <span> tu transaccion se revertira si el precio cambia mas de este % en contra tuyo</span>
            }
          />
        </RowFixed>
        <RowBetween>
          <Option
            onClick={() => {
              if (swap) {
                localStorage.setItem('sSlipagge', '0.5')
                setSlippageInput('0.5')
              } else {
                localStorage.setItem('bSlipagge', '3')
                setSlippageInputBridge('3')
              }
            }}
            active={swap ? slippageInput == '0.5' : slippageInputBridge == '3'}
          >
            <span>Auto</span>
          </Option>
          <OptionCustom active={userSlippageTolerance !== 'auto'} warning={!!slippageError} tabIndex={-1}>
            <RowBetween>
              <Input
                placeholder={placeholderSlippage && placeholderSlippage?.toFixed(2)}
                value={swap ? slippageInput : slippageInputBridge}
                onChange={(e) => parseSlippageInput(e.target.value)}
                color={slippageError ? 'red' : ''}
              />
              %
            </RowBetween>
          </OptionCustom>
        </RowBetween>
      </AutoColumn>
    </AutoColumn>
  )
}
