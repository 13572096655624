import styled, { ThemeContext } from 'styled-components/macro'
import { useCallback, useContext } from 'react'

import BigNumber from 'bignumber.js'
import { Text } from 'rebass'


const StyledPriceContainer = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  grid-template-columns: 1fr auto;
  grid-gap: 0.25rem;
  display: flex;
  flex-direction: row;
  text-align: left;
  flex-wrap: wrap;
  padding: 8px 0;
  user-select: text;
`

export default function TradePrice({ price, showInverted, setShowInverted }: any) {
  const theme = useContext(ThemeContext)

  const formatTokenAmountOnly = (token: any, amount: any) => {
    if (!amount) {
      return '0.0'
    }

    let floated
    if (typeof amount === 'string') {
      if (amount === '0') {
        return '0.0'
      }

      floated = new BigNumber(amount).shiftedBy(-token.decimals)
    } else {
      floated = amount

      return floated.decimalPlaces(5)
    }
  }

  const label = showInverted ? `${price?.fromToken.symbol}` : `${price?.fromToken.symbol} `
  const labelInverted = showInverted ? `${price?.toToken.symbol} ` : `${price?.toToken.symbol}`
  const flipPrice = useCallback(() => setShowInverted(!showInverted), [setShowInverted, showInverted])

  const text = `Price ${
    formatTokenAmountOnly(price.toToken.symbol, price.executionPrice) + ' ' + label + ' per ' + labelInverted ?? '-'
  }`

  return (
    <StyledPriceContainer
      onClick={(e) => {
        e.stopPropagation() // dont want this click to affect dropdowns / hovers
        flipPrice()
      }}
      title={text}
    >
      <Text color={theme.text3} fontWeight={600} fontSize={16} style={{ display: 'inline' }}>
        {text}
      </Text>{' '}

    </StyledPriceContainer>
  )
}
