import {  useCallback } from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from '../TransactionConfirmationModal'

import BridgeModalFooter from './BridgeModalFooter'
import BridgeModalHeader from './BridgeModalHeader'

export default function ConfirmBridgeModal({
  trade,
  outdatedRoutes,
  routesLoading,
  successfullyswapped,
  error,
  hasError,
  message,
  close,
  onAcceptChanges,
  allowedSlippage,
  onConfirm,
  onDismiss,
  bridgeErrorMessage,
  isOpen,
  attemptingTxn,
  isSwapping,
}: any) {
  const showAcceptChanges = false

  const modalHeader = useCallback(() => {
    return trade ? (
      <BridgeModalHeader
        outdatedRoutes={outdatedRoutes}
        isSwapping={isSwapping}
        routesLoading={routesLoading}
        trade={trade}
        allowedSlippage={allowedSlippage}
        showAcceptChanges={showAcceptChanges}
        onAcceptChanges={onAcceptChanges}
      />
    ) : null
  }, [allowedSlippage, onAcceptChanges, showAcceptChanges, trade, isSwapping, outdatedRoutes, routesLoading])

  const modalBottom = useCallback(() => {
    return trade ? (
      <BridgeModalFooter
        isSwapping={isSwapping}
        outdatedRoutes={outdatedRoutes}
        onConfirm={onConfirm}
        trade={trade}
        disabledConfirm={showAcceptChanges}
        bridgeErrorMessage={bridgeErrorMessage}
      />
    ) : null
  }, [onConfirm, showAcceptChanges, bridgeErrorMessage, trade, outdatedRoutes])

  const pendingText = (
    <span>
      Bridging {trade?.inputAmount?.toSignificant(6)} {trade?.inputAmount?.currency?.symbol} for{' '}
      {trade?.outputAmount?.toSignificant(6)} {trade?.outputAmount?.currency?.symbol}
    </span>
  )
  const confirmationContent = useCallback(
    () =>
      hasError && error !== 'Cannot add property doneAt, object is not extensible' ? (
        <TransactionErrorContent onDismiss={onDismiss} message={error} />
      ) : (
        <ConfirmationModalContent
          swap={false}
          trade={trade}
          message={message}
          title={<span>Confirmar Swap</span>}
          isSwapping={isSwapping}
          onDismiss={close}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, message, bridgeErrorMessage, isSwapping, trade, error, hasError]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={close}
      attemptingTxn={attemptingTxn}
      hash={successfullyswapped}
      content={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={trade?.outputAmount?.currency}
    />
  )
}
